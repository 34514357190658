import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FramesService } from "../../../routes/services/frames.service";
import {
  SettingModalComponent,
  SettingInterface,
} from "../settings-modal.component";

import { formatDate } from "@angular/common";
import * as moment from "moment-timezone";

@Component({
  selector: "app-alert-settings",
  templateUrl: "./alerts.html",
  styleUrls: ["../settings-modal.component.scss"],
})
export class AlertsSettingComponent implements OnInit {
  @Input("feeder") feeder;
  @Input("test") test: SettingInterface;
  @Input("pondSelected") pondSelected;
  @Input("selectedFeeders") selectedFeeders = [];
  @Input("showView") showView;
  @Input("pond") pond;
  /* Vamos a declarar las alertas que vamos a mostrar  */
  types = [
    "ANALOG_READING",
    "CURRENT",
    "FEED_RATE",
    "SENSORS_ENABLED",
    "SET_POINTS",
    "CONFIRM_POINTS",
    "REQUEST_SENSORS_ALL_ITEMS",
    "SENSOR_FREQUENCIES_RESPONSE",
    "CHANGE_SETTING_FRAME",
    "CHANGE_ALERT_DISPENSER",
    "ANALOG_READING_RESPONSE",
  ];
  constructor(
    public settingModal: SettingModalComponent,
    public framesService: FramesService
  ) {}

  ngOnInit(): void {}

  validateTotalAlerts(feeder) {
    let count = 0;

    for (const key in this.test) {
      if (Object.prototype.hasOwnProperty.call(this.test, key)) {
        const value = this.test[key];
        if (value) {
          let val = this.validateItemsArray(value, feeder).length;
          count = count + val;
        }
      }
    }
    return count;
  }

  validateItemsArray(test: Array<any>, feeder) {
    let array = test;
    if (this.pond) {
      let allmac: any = [];

      allmac = this.pond.linked_feeders.map((item) => item.mac);
      const condition = (item: any) =>
        allmac.indexOf(item.feeder.mac) !== -1 &&
        item.feeder.mac === feeder.mac;

      array = test.filter(condition);
    }

    if (this.selectedFeeders.length > 0 && feeder) {
      let allmacfeeder = this.selectedFeeders.map((item: any) => item.mac);

      const condition = (item) =>
        allmacfeeder.indexOf(item.feeder.mac) !== -1 &&
        item.feeder.mac === feeder.mac;

      test.length > 0 && (array = test.filter(condition));
    }

    return array;
  }
  validateTotalClickAlert() {
    let count = 0;
    for (const key in this.showView) {
      if (Object.prototype.hasOwnProperty.call(this.showView, key)) {
        const element = this.showView[key];
        if (element) {
          count++;
        }
      }
    }
    return count;
  }

  individualAction(item, i) {
    const { nuevo, feeder } = item;
    const idFeeder = `?id_in=${item.id ? item.id : feeder.id}`;
    /* Agregar loadings a peticiones individuales  */
  }
  setClass = (type) => {
    switch (type) {
      case "error":
        return "list-group-item classError mb-2";
      case "pending":
        return "list-group-item classPending mb-2";
      case "success":
        return "list-group-item classSuccess";
    }
  };

  validateText(
    type:
      | "ANALOG_READING"
      | "CURRENT"
      | "FEED_RATE"
      | "SENSORS_ENABLED"
      | "SET_POINTS"
      | "CONFIRM_POINTS"
      | "REQUEST_SENSORS_ALL_ITEMS"
      | "SENSOR_FREQUENCIES_RESPONSE"
      | "CHANGE_SETTING_FRAME"
      | "CHANGE_ALERT_DISPENSER",
    feeder: any,
    value
  ) {
    let label = "";

    const {
      status_port,
      dispenser_alert,
      sprinkler_current,
      dispenser_current,
      feed_rate,
      sensors,
      status_dispenser_current,
      status_sprinkler_current,
      status_dispenser_alert_enabled,
    } = feeder;

    if (type === "ANALOG_READING") {
      if (value.nuevo.frecuency_mode) {
        const text = () => {
          if (feeder.sensors.frequencies) {
            const frequencies = feeder.sensors.frequencies;
            if (!frequencies) {
              return "No hay una frecuencia activa";
            }
            if (frequencies.length === 0) {
              return "Frecuencia cada hora";
            }
            if (frequencies.length === 3) {
              return `Frecuencia activada cada 3 horas ${frequencies}`;
            }
            if (frequencies.length === 5) {
              return `Frecuencia activada cada 5 horas ${frequencies}`;
            }
          }
        };
        label = `<div> <b>${text()}</b></div>`;
      } else {
        label = `<div> <b>
         Puerto: A${status_port} <br>
         Alerta de dosificador: ${
           dispenser_alert ? "Habilitado" : "Deshabilitado"
         }
         </b>
       </div>`;
      }
    }
    if (type === "CURRENT") {
      label = `<div> <b>
        Aspersor:${sprinkler_current} <br>
        Dosificador:${dispenser_current}</b>
      </div> `;
    }
    if (type === "FEED_RATE") {
      label = `<div> <b>
      Tasa de alimentación:${feed_rate}</b>
    </div> `;
    }
    if (type === "SENSORS_ENABLED") {
      label = `<div> <b>
      sensor: ${
        sensors
          ? sensors.sensors_enabled
            ? "Habilitado"
            : "Deshabilitado"
          : ""
      }</b>
    </div> `;
    }
    if (type === "SET_POINTS") {
      label = `<div> <b>
      set points: ${
        sensors
          ? sensors.set_point_enabled
            ? "Habilitado"
            : "Deshabilitado"
          : ""
      }</b>
    </div> `;
    }
    if (type === "CONFIRM_POINTS") {
      label = `<div><b>Los min, max de temperatura y humedad se han enviado correctamente</b></div> `;
    }
    if (type === "REQUEST_SENSORS_ALL_ITEMS") {
      let Allpropiety = [
        /* Temperatura agua */
        "water_temp",
        "water_temp_min",
        "water_temp_min_time",
        "water_temp_max",
        "water_temp_max_time",
        /* Temperatura ambiente */
        "temp",
        "temp_min",
        "temp_min_time",
        "temp_max",
        "temp_max_time",
        /* Humedad */
        "hum",
        "hum_min",
        "hum_min_time",
        "hum_max",
        "hum_max_time",
      ];
      let tempLabel = "";
      const validateTimeZone = (feeder, prop) => {
        let propiety = [
          "water_temp_min_time",
          "water_temp_max_time",
          "temp_min_time",
          "temp_max_time",
          "hum_min_time",
          "hum_max_time",
        ];
        if (propiety.includes(prop)) {
          const { farm } = feeder;

          if (farm.timezone) {
            let stringTime = `${feeder.sensors[prop]}+0000`;
            let time = stringTime.replace(" ", "T");

            const momentTi = moment(new Date(time))
              .tz(farm.timezone)
              .format("YYYY-MM-DD HH:mm:ss");
            return momentTi;
          }
        }
        return feeder.sensors[prop];
      };
      Allpropiety.forEach((item, idx) => {
        if (feeder.sensors[item]) {
          if (idx === 5 || idx === 10) {
            tempLabel += `</div>`;
          }
          if (idx === 0 || idx === 5 || idx === 10) {
            tempLabel += `<div class="col-12 col-md-6 col-lg-4 ">`;
          }
          tempLabel += `<b >${item}: ${validateTimeZone(
            feeder,
            item
          )}</b> <br>`;
          if (idx === 14) {
            tempLabel += `</div>`;
          }
        }
      });
      label = `<div class='row'>${tempLabel}</div>`;
    }
    if (type === "SENSOR_FREQUENCIES_RESPONSE") {
      label = `<div><b>Se ha confirmado la configuracion de horas</b></div> `;
    }
    if (type === "CHANGE_SETTING_FRAME") {
      label = `<div> <b>
      Limite dosificador:${
        status_dispenser_current
          ? `${status_dispenser_current}A`
          : "20A (default)"
      }<br>
      Limite aspersor:${
        status_sprinkler_current
          ? `${status_sprinkler_current}A`
          : "5A (default)"
      } 
              </b></div>
      `;
    }
    if (type === "CHANGE_ALERT_DISPENSER") {
      label = `<div> <b>
      Alerta de dosificador: ${
        status_dispenser_alert_enabled ? "Habilitado" : "Deshabilitado"
      }</b>
    </div> `;
    }

    return label;
  }
}
