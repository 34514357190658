import { Component, Input, OnInit } from "@angular/core";
import { WaterwiseService } from "../../../routes/services/waterwise.service";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";
import { Options } from "@angular-slider/ngx-slider";
import { ConfigurationDeviceService } from "../../../routes/services/configuration-device.service";

@Component({
  selector: "app-config-automatic-request-device",
  templateUrl: "./automatic_request.html",
  styleUrls: ["./automatic_request.scss"],
})
export class AutomaticRequestDeviceComponent implements OnInit {
  @Input() type: "feeder" | "waterwise" | "farm" | "pond" = "feeder";
  @Input() id: any = null;
  request_frequency_select = "05";
  request_frequency_radio = "H";
  interval: "EVERY_HOUR" | "EVERY_3_HOURS" | "EVERY_5_HOURS" = "EVERY_HOUR";
  hoursSelected: any = [];
  hours = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];

  /* "auto_sensor_request": {
    "value": [
      23
    ],
    "mode": 1,
    "mode_name": "string"
  }, */
  constructor(
    public waterwiseService: WaterwiseService,
    public framesService: FramesService,
    public globalFunction: GlobalFunction,
    public configurationDeviceService: ConfigurationDeviceService
  ) {}

  ngOnInit(): void {
    this.configurationDeviceService
      .getConfiguration(this.id, this.type)
      .subscribe((resp) => {
        if (resp.auto_hydro_sensor_request) {
          this.hoursSelected = resp.auto_hydro_sensor_request.value;
          this.interval = resp.auto_hydro_sensor_request.mode_name;
        }
      });
  }

  validatechangedays(type) {
    this.interval = type;
    if (this.hoursSelected) {
      if (this.hoursSelected.length === 5) {
        this.hoursSelected.splice(3, 5);
      }
      if (this.hoursSelected.length === 4) {
        this.hoursSelected.splice(3, 4);
      }
    }
  }
  changeSelectHours(val) {
    if (val) {
      if (this.interval === "EVERY_3_HOURS" && this.hoursSelected.length < 3) {
        this.hoursSelected.push(val);
      }
      if (this.interval === "EVERY_5_HOURS" && this.hoursSelected.length < 5) {
        this.hoursSelected.push(val);
      }
      this.hoursSelected.sort((a, b) => a - b);
    }
  }
  filterSelectItem(array) {
    let ejemplo = this.hoursSelected;
    let newArray = [...array];

    array.forEach((item, idx) => {
      ejemplo.forEach((ejem) => {
        if (item === Number(ejem)) newArray.splice(idx, 1);
      });
    });

    return newArray;
  }
  deleteHour(i) {
    this.hoursSelected.splice(i, 1);
  }
  submitAutomaticRequest() {
    const hours = this.hoursSelected;
    let mode = {
      EVERY_HOUR: 1,
      EVERY_3_HOURS: 2,
      EVERY_5_HOURS: 3,
    };

    let obj = {
      auto_hydro_sensor_request: {
        mode: mode[this.interval],
        value:
          mode[this.interval] > 1
            ? this.hoursSelected.map((item) => Number(item))
            : [],
      },
    };
    this.configurationDeviceService
      .patchConfiguration(this.id, this.type, obj, "")
      .subscribe((resp) => {
      });
  }
  validateOptions = (val): Options => {
    return {
      floor: val.min,
      ceil: val.max,
      translate: (value: number): string => {
        const format_value = value.toLocaleString("en-us", {
          minimumFractionDigits: 2,
        });

        return format_value + val.measurementThreshold;
      },
    };
  };

  setConfig = () => {
    const query:string = `?id_in=${this.id}`;
    const hours = this.hoursSelected;
    const petition = this.type === "feeder" ? "FramePost" : "waterwiseMasiveFrames";

    if (this.interval === "EVERY_HOUR") {
      this.framesService[petition]("sensor/frequency/set_hourly", query)
        .subscribe((item) => {
          this.globalFunction.animation(
            "success",
            "Éxito",
            "Se han guardado los datos correctamente"
          );
        });
    }
    if (this.interval === "EVERY_3_HOURS") {
      if (this.hoursSelected.length === 3) {
        let obj = {
          first: hours[0],
          second: hours[1],
          third: hours[2],
        };

        this.framesService
          .FramePost("sensor/frequency/set_3_hours", query, obj)
          .subscribe((item) => {
            this.globalFunction.animation(
              "success",
              "Éxito",
              "Se han guardado los datos correctamente"
            );
          });
      }
    }
    if (this.interval === "EVERY_5_HOURS") {
      if (hours.length === 5) {
        let obj = {
          first: hours[0],
          second: hours[1],
          third: hours[2],
          fourth: hours[3],
          fifth: hours[4],
        };
        this.framesService
          .FramePost("sensor/frequency/set_5_hours", query, obj)
          .subscribe((item) => {
            this.globalFunction.animation(
              "success",
              "Éxito",
              "Se han guardado los datos correctamente"
            );
          });
      }
    }
  };
}
