import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WaterwiseService } from "../../routes/services/waterwise.service";
import { ClientsService } from "../../routes/services/clients.service";
import { StorageService } from "../../routes/services/storage.service";
import { FeedersService } from "../../routes/services/feeder.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FarmService } from "../../routes/services/farm.service";
import { PondService } from "../../routes/services/pond.service";

@Component({
  selector: "app-modal-feeder-form",
  templateUrl: "./feederForm.html",
  styleUrls: ["./feederForm.scss"],
})
export class FeederFormComponent implements OnInit {
  @Input("mode") mode: "feeder" | "waterwise" = "feeder";
  @Input("feeder") feeder: any;
  @Input("type") type?: "" | "masive";
  @Input("selected") selected?: any = [];

  @Output() successPetition = new EventEmitter();

  feederForm: FormGroup = new FormGroup({
    brand: new FormControl("", Validators.required),
    dispenser: new FormControl("", Validators.required),
    pond: new FormControl("", Validators.required),
    mac: new FormControl("", [
      Validators.required,
      Validators.pattern(`^([0-9a-fA-F]{2}[:-]){7}[0-9a-fA-F]{2}$`),
    ]),
    name: new FormControl("", Validators.required),
    series: new FormControl("", Validators.required),
    client: new FormControl(""),
    filteredBusinessName: new FormControl(""),
  });
  selectFarms: FormGroup = new FormGroup({
    client: new FormControl(""),
    farm: new FormControl("", Validators.required),
    pond: new FormControl("", Validators.required),
  });

  ponds: any = [];
  farms: any = [];
  dispensers: any = [];
  brands: any = [];

  clients = [];
  filteredClients: any = [];

  loadingDispensers = false;
  loadingBrands = false;
  loadingClients = false;
  loadingFarms = false;
  loadingPonds = false;

  constructor(
    public waterwiseService: WaterwiseService,
    public feedersService: FeedersService,
    public globalFunction: GlobalFunction,
    public clientsService: ClientsService,
    public storageService: StorageService,
    public farmService: FarmService,
    public pondService: PondService
  ) {}

  ngOnDestroy(): void {
    this.feeder = "";
  }

  ngOnInit(): void {
    this.getClients();
    if (this.mode === "feeder") {
      this.getFeedersDispensers();
      this.getFeedersBrand();
      if (this.feeder) this.setValue(this.feeder);
    }
    if (this.mode === "waterwise") {
      const client_id = this.storageService.getClientID();
      if (client_id) {
        this.getFarms(client_id);
      }
    }
  }

  setValue(value) {
    value.farm && this.getPonds(value.farm.id);
    value.client && this.getFarms(value.client.id);

    this.feederForm.setValue({
      ...this.feederForm.value,
      brand: value.brand ? value.brand.id : "",
      dispenser: value.dispenser ? value.dispenser.id : "",
      pond: value.pond ? value.pond : "",
      mac: this.globalFunction.agregarCaracter(value.mac, ":", 2),
      name: value.name,
      series: value.series,
      client: value.client ? value.client.id : "",
    });
    this.selectFarms.setValue({
      farm: value.pond ? value.farm.id : "",
      pond: value.pond ? value.pond.id : "",
      client: value.client ? value.client.id : "",
    });
  }

  register(value) {
    let { farm, pond } = this.selectFarms.value;

    let { client } = this.feederForm.value;

    let obj: any = {};
    if (this.mode === "feeder") {
      if (this.type === "masive") {
        obj = {};

        value.brand && (obj.brand = value.brand);
        value.dispenser && (obj.dispenser = value.dispenser);
        value.client && (obj.client = value.client);

        farm && (obj.farm = farm);

        this.feedersService
          .EditMasiveFeeders(
            `?id_in=${this.selected.map((item) => item.id)}`,
            obj
          )
          .subscribe((resp) => {
            this.successPetition.emit(resp);
          });
      }

      if (!this.type) {
        const { brand, mac, name, series, dispenser } = value;
        obj = {
          brand,
          dispenser,
          mac,
          name,
          series,
          client,
          pond,
          farm,
        };
        if (this.feeder) {
          this.feedersService.EditFeeders(this.feeder.id, obj).subscribe(
            (resp) => {
              this.successPetition.emit(resp);
            },
            (err) => {}
          );
        } else {
          this.feedersService.PostFeeders(obj).subscribe(
            (resp) => {
              this.successPetition.emit(resp);
            },
            (err) => {}
          );
        }
      }
    } else {
      obj = {
        client: Number(client),
        pond: Number(pond),
        farm: Number(farm),
      };
      const query_selected = `?id_in=${this.selected.map((item) => item.id)}`;
      this.waterwiseService
        .multipleUpdateWaterwise(query_selected, obj)
        .subscribe(
          (resp) => {
            this.successPetition.emit(resp);
          },
          (err) => {}
        );
    }
  }

  getFeedersDispensers() {
    this.loadingDispensers = true;

    this.feedersService.ListDispenser("").subscribe((resp) => {
      this.loadingDispensers = false;
      this.dispensers = resp;
    });
  }

  getFeedersBrand() {
    this.loadingBrands = true;

    this.feedersService.ListFeedersbrand("").subscribe((resp: any) => {
      this.loadingBrands = false;
      this.brands = resp;
    });
  }

  getClients() {
    this.loadingClients = true;
    const fields = ["id", "business_name"];

    this.clientsService.getClients(`?fields=${fields}`).subscribe((resp) => {
      this.loadingClients = false;
      this.clients = resp;
      this.filterClientsList();
    });
  }

  filterClientsList() {
    const { filteredBusinessName } = this.feederForm.value;

    if (filteredBusinessName) {
      this.filteredClients = this.clients.filter((item: any) => {
        return (
          item.business_name
            .toLowerCase()
            .indexOf(filteredBusinessName.toLowerCase()) > -1
        );
      });
    } else {
      this.filteredClients = this.clients;
    }
  }

  getFarms(id) {
    if (id) {
      this.loadingFarms = true;
      const fields = ["id", "name"];
      const query_client = id ? `&client=${id}` : "";
      this.farmService
        .getFarms(`?fields=${fields}${query_client}`)
        .subscribe((resp) => {
          this.loadingFarms = false;
          this.feederForm.setValue({
            ...this.feederForm.value,
            client: id,
          });
          this.farms = resp;
        });
    } else {
      this.farms = [];
      this.selectFarms.controls["pond"].setValue("");
      this.selectFarms.controls["farm"].setValue("");
    }
  }

  getPonds = async (farm) => {
    if (farm) {
      this.loadingPonds = true;
      try {
        const fields = ["id", "name"];
        let data = await this.pondService
          .getPonds(`?farm_id=${farm}&fields=${fields}`)
          .toPromise();

        this.loadingPonds = false;

        this.ponds = data;
        this.selectFarms.setValue({
          ...this.selectFarms.value,
          farm,
        });
      } catch (error) {}
    }
  };
}
