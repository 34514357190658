import { Component, Input, OnInit } from "@angular/core";
import { resourses, resousers } from "../contants";
import { WaterwiseService } from "../../../routes/services/waterwise.service";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";
import { Options } from "@angular-slider/ngx-slider";
import { ConfigurationDeviceService } from "../../../routes/services/configuration-device.service";
import { ParametricsService } from "../../../routes/services/parametrics.service";
import { StorageService } from "../../../routes/services/storage.service";
import { sensors } from "../../../routes/Clients/device/constants";

@Component({
  selector: "app-config-min-max-sensors-device",
  templateUrl: "./config.html",
  styleUrls: ["./config.scss"],
})
export class ConfigMinMaxComponent implements OnInit {
  @Input() type: "feeder" | "waterwise" | "farm" | "pond" = "feeder";
  @Input() id: number | "" = "";

  @Input() massiveUpdate = false;
  @Input() selectedItems = [];

  resourses_data: resousers[] = [];

  constructor(
    public waterwiseService: WaterwiseService,
    public framesService: FramesService,
    public globalFunction: GlobalFunction,
    public configurationDeviceService: ConfigurationDeviceService,
    public parametricService: ParametricsService,
    public storageService: StorageService
  ) {}
  ngOnDestroy(): void {}
  ngOnInit(): void {
    this.resourses_data = resourses.map((item) => {
      const sensor = sensors.find((sen) => sen.preference === item.prop);
      if (sensor) {
        return {
          ...item,
          min: sensor.min,
          max: sensor.max,
          preference: sensor.preference,
          measurementThreshold: sensor.measurementThreshold,
        };
      }
      return item;
    });
    this.get_data();
  }

  get_data() {
    const farm_id = Number(this.storageService.localValue.farm_id);

    if (this.id) {
      this.getConfigById(this.id);
      this.getPreferenceByFarm(farm_id);
    }
    if (this.massiveUpdate && this.selectedItems.length === 1) {
      this.getConfigById(this.selectedItems[0]["id"]);
      this.getPreferenceByFarm(farm_id);
    }
    if (this.massiveUpdate && this.selectedItems.length > 1) {
      this.getConfigById(farm_id, "farm");
    }
  }

  getPreferenceByFarm(farm_id: number) {
    this.parametricService
      .getParametricPreference(`?farm_id=${farm_id}`)
      .subscribe((resp) => {
        if (resp.length > 0) {
          let resourses = resp[0];
          this.resourses_data.forEach((element: any, idx) => {
            if (
              Object.prototype.hasOwnProperty.call(
                resourses,
                element.preference
              )
            ) {
              this.resourses_data[idx].check = resourses[element.preference]
                ? resourses[element.preference]
                : false;
            }
            this.storageService.checks_parametry_preference = resourses;
          });
        }
      });
  }
  getConfigById(id: number, type = this.type) {
    this.configurationDeviceService
      .getConfiguration(id, type)
      .subscribe((resp) => {
        this.resourses_data.forEach((item, idx) => {
          this.resourses_data[idx].min_value = resp[item.preference + `_min`]
            ? resp[item.preference + `_min`]
            : item.min;

          this.resourses_data[idx].max_value = resp[item.preference + `_max`]
            ? resp[item.preference + `_max`]
            : item.min;
        });
      });
  }
  submitAutomaticRequest() {
    let obj = {};
    this.resourses_data.forEach((item) => {
      obj[item.preference + `_min`] = item.min_value;
      obj[item.preference + `_max`] = item.max_value;
    });
    let query = this.massiveUpdate
      ? `?id_in=${this.selectedItems.map((item: any) => item.id)}`
      : "";
    this.configurationDeviceService
      .patchConfiguration(this.id, this.type, obj, query)
      .subscribe((resp) => {
      });
  }

  validateOptions = (val): Options => {
    let step = val.preference === "SG" ? { step: 0.00001 } : {};

    return {
      floor: val.min,
      ceil: val.max,
      translate: (value: number): string => {
        const format_value = value.toLocaleString("en-us", {
          minimumFractionDigits: 2,
        });
        return format_value + val.measurementThreshold;
      },
      ...step,
    };
  };
  changeCheck(item) {
    const farm_id = this.storageService.localValue.farm_id;

    this.parametricService
      .patchParametricPreferenceChangefarm(farm_id, {
        [item.preference]: item.check,
      })
      .subscribe((resp) => {
        this.storageService.checks_parametry_preference[item.preference] =
          item.check;
      });
  }
  removeConfiguration() {
    this.configurationDeviceService
      .deleteConfiguration(this.id, this.type)
      .subscribe((resp) => {
        this.resourses_data.forEach((item, idx) => {
          this.resourses_data[idx].min_value = resp[item.preference + `_min`]
            ? resp[item.preference + `_min`]
            : item.min;

          this.resourses_data[idx].max_value = resp[item.preference + `_max`]
            ? resp[item.preference + `_max`]
            : item.min;
        });
      });
  }
}
