import { Component, Input, OnInit } from "@angular/core";
import { GlobalFunction } from "../../../routes/services/globalFuntion";

@Component({
  selector: "app-view-device",
  templateUrl: "./view_device.html",
  styleUrls: ["./view_device.scss"],
})
export class ViewDeviceComponent implements OnInit {
  @Input() menu = null;
  @Input() device = null;

  constructor(public globalFunction: GlobalFunction) {}

  ngOnInit(): void {}

  individualAction(alert, menu) {
    /* Validacion de peticiones individuales */
  }

  validateColorImage = (data, type, success, warning, danger) => {
    let { dispenser, sprinkler, panel, battery } = data;

    if (type === "battery") {
      if (battery > 12.5) {
        return success;
      }
      if (battery > 11.5 && battery <= 12.5) {
        return warning;
      }
      if (battery <= 11.5) {
        return danger;
      }
    }
    if (type === "panel") {
      if (panel > 15) {
        return success;
      }
      if (panel > 12 && panel <= 15) {
        return warning;
      }
      if (panel <= 12) {
        return danger;
      }
    }
    if (type === "sprinkler") {
      return sprinkler ? danger : success;
    }
    if (type === "dispenser") {
      return dispenser ? danger : success;
    }

    return danger;
  };

  validateActive(val, type = "") {
    if (val) {
      return "bg-alert-success";
    }
    if (type === "power" || type === "dispenser") {
      return "bg-alert-danger";
    }
    return "bg-alert-secondary";
  }
}
