import { permissions_client } from "./Clients/permissions/groups/add/permissions";
import {
  Food,
  Apks,
  Admins,
  Clients,
  Dashboard,
  Calibration,
  Maintenance,
  Notifications,
  TrayTemplates,
  FeedersControls,
  MovementsHistorical,
} from "./constant/admin";
import {
  Farms,
  Diets,
  Tasks,
  Alerts,
  Weather,
  Devices,
  Ranking,
  Plantings,
  SubClients,
  Biometries,
  Waterwises,
  FarmDashboard,
  FeedingAnalisis,
  UsersPermissions,
  Comparison,
} from "./constant/clients";

export class Menu {
  constructor() {}
  /* client_permissions es solo para subclientes
son permisos del cliente a considerar */
  public static menu(user, mode, my_permissions?, client_permissions?) {
    const arraySUperUsuario = [
      Dashboard,
      Admins,
      Clients,
      FeedersControls,
      Food,
      TrayTemplates,
      Maintenance,
      MovementsHistorical,
      Notifications,
      Apks,
      Calibration,
      Waterwises,
    ];

    let ArrayCliente = [
      FarmDashboard,
      Farms,
      Alerts,
      Devices,
      Plantings,
      Diets,
      Biometries,
      Ranking,
      FeedingAnalisis,
      Weather,
      SubClients,
      UsersPermissions,
      /* Comparison */
    ];

    const ArraySubcliente = [
      FarmDashboard,
      Farms,
      Alerts,
      Devices,
      Plantings,
      Diets,
      Biometries,
      Ranking,
      FeedingAnalisis,
      Weather,
      UsersPermissions,
      // Tasks,
    ];

    let sidebar_section = localStorage.getItem("sidebar_section");
    let arraySection = sidebar_section ? JSON.parse(sidebar_section) : [];

    const ValidateSection = (array, sections) => {
      if (sections.length > 0) {
        let findSidebarAlert = sections.find(
          (item) => item === "sidebar_alert"
        );
        let findSidebarFeeders = sections.find(
          (item) => item === "sidebar_feeders"
        );

        const validateUrl = (item, url) => {
          if (item.hasOwnProperty("submenu")) {
            return item.submenu.find((submenu) => submenu.link === url);
          } else {
            return item.link === url;
          }
        };

        if (findSidebarAlert) {
          let indexMenu = array.findIndex((item) =>
            validateUrl(item, "/alerts")
          );

          if (indexMenu >= 0 && !array[indexMenu].hasOwnProperty("submenu")) {
            array.splice(indexMenu, 1);
          }
        }

        if (findSidebarFeeders) {
          let indexMenu = array.findIndex((item) =>
            validateUrl(item, "/alimentadoras")
          );

          if (indexMenu >= 0 && array[indexMenu].hasOwnProperty("submenu")) {
          }
          if (indexMenu >= 0 && !array[indexMenu].hasOwnProperty("submenu")) {
            array.splice(indexMenu, 1);
          }
        }
      }

      return array;
    };
    const validate_all_permissions = (permission) => {
      if (my_permissions) {
        /* Valida si los permisos existen en algun subcliente */
        const validate = Object.prototype.hasOwnProperty.call(
          my_permissions,
          permission
        );
        return validate;
      }
      return true;
    };
    /* FUNCION PARA VALIDAR SI PUEDE MOSRAR UNA PANTALLA EN ESPECIFICO */

    const validation_permisions = (item) => {
      const exist_permission_local_client = [...permissions_client].find(
        (perm) => perm.codename === item.permission
      );
      console.log(item, exist_permission_local_client);
      if (exist_permission_local_client) {
        if (exist_permission_local_client.include_subclient) {
          if (exist_permission_local_client) {
            const exist_in_client = Object.prototype.hasOwnProperty.call(
              client_permissions,
              item.permission
            );
            if (exist_in_client) {
              return validate_all_permissions(item.permission);
            } else {
              return false;
            }
          }
        }
        if (!exist_permission_local_client.include_subclient) {
          const exist_in_client = Object.prototype.hasOwnProperty.call(
            client_permissions,
            item.permission
          );
          /* validacion de permisos solo del cliente */
          return exist_in_client;
        }
      }
      return validate_all_permissions(item.permission);
    };

    const validatePermissionsSubclient = (array) => {
      const validate_submenu = array.map((item) => {
        if (item.submenu && item.submenu.length > 0) {
          return {
            ...item,
            submenu: item.submenu.filter((submenu) =>
              validation_permisions(submenu)
            ),
          };
        }
        return item;
      });
      console.log(validate_submenu);
      const arrayFIlter = validate_submenu.filter((item) => {
        if (
          item.permission !== null &&
          my_permissions !== null &&
          client_permissions !== null &&
          user.use_permissions
        ) {
          validation_permisions(item);
        }
        return true;
      });

      return arrayFIlter;
    };
    const validatePermissionsClient = (array) => {
      /* PERMISOS OBLIGATORIOS A CONSIDERAR EN CLIENTE SIDEBAR */
      const considered = array
        .filter((item) => item.use_client && item.permission)
        .map((item) => item.permission);

      const items = array.filter((item) => {
        if (item.use_client) {
          let validate = considered.some((item) =>
            Object.prototype.hasOwnProperty.call(my_permissions, item)
          );
          return validate;
        }
        return true;
      });
      return items;
    };
    switch (user.user_type) {
      /* Super Usuario */
      case -1:
        if (!mode) {
          let array = arraySUperUsuario.slice(0);
          return ValidateSection(array, arraySection);
        } else {
          ArrayCliente.push(MovementsHistorical);
          return ValidateSection(ArrayCliente, arraySection);
        }

      /* Administrador */
      case 0:
        const alerts_only = user.permissions.alerts_only || false;
        if (alerts_only) {
          return [Maintenance];
        }
        const permissions_user = user.permissions.users
          ? [Admins, Clients, MovementsHistorical]
          : [];
        const permissions_food = user.permissions.food ? [Food] : [];
        const permissions_maintenance = user.permissions.monitoring
          ? [Maintenance]
          : [];

        let array: Array<any> = [
          Dashboard,
          ...permissions_user,
          ...permissions_maintenance,
          FeedersControls,
          ...permissions_food,
          TrayTemplates,
          Notifications,
          Apks,
          Waterwises,
          Calibration,
        ];

        if (mode) {
          /*   ArrayCliente.push(Biometrias); */
          return ValidateSection(ArrayCliente, arraySection);
        } else {
          return ValidateSection(array, arraySection);
        }

      /* Cliente */
      case 1:
        const view = [...ArrayCliente, ...arraySection];
        return validatePermissionsClient(view);
      case 2:
        /*   const sub = validatePermissionsClient(ArraySubcliente, permissions);
         */

        return validatePermissionsSubclient(ArraySubcliente);
    }
  }
}
