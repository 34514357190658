import { ConfigurationDeviceService } from "../../routes/services/configuration-device.service";
import { VersioningService } from "./../../routes/services/versioning.service";
import { PermissionsService } from "../../routes/services/permissions.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { SettingsService } from "../../core/settings/settings.service";
import { ClientsService } from "../../routes/services/clients.service";
import { StorageService } from "../../routes/services/storage.service";
import { FeedersService } from "../../routes/services/feeder.service";
import { UsersService } from "./../../routes/services/users.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { FramesService } from "../../routes/services/frames.service";
import { ThemesService } from "../../core/themes/themes.service";
import { AuthService } from "../../routes/services/auth.service";
import { FarmService } from "../../routes/services/farm.service";
import { Subscription } from "rxjs";



const screenfull = require("screenfull");

@Component({
  selector: "app-offsidebar",
  templateUrl: "./offsidebar.component.html",
  styleUrls: ["./offsidebar.component.scss"],
})
export class OffsidebarComponent implements OnInit, OnDestroy {
  currentTheme: any;

  feeders = [];

  currentVersion: any;

  autimaticTestTime = { hour: 0, minute: 0 };

  user: any;

  newVersion: any;
  needsRefresh: boolean = false;

  language = {
    code: "",
    text: "",
    image: "",
  };

  mode_configuration: "global" | "sensor" = "global";
  is_client = false;
  use_fuzzy_logic = false;
  fuzzy_dpi = "";
  status_test_alert = false;
  constructor(
    public configurationDeviceService: ConfigurationDeviceService,
    public permissionsService: PermissionsService,
    public versionService: VersioningService,
    public globalFunction: GlobalFunction,
    public storageService: StorageService,
    public translator: TranslatorService,
    public clientService: ClientsService,
    public feederService: FeedersService,
    public framesService: FramesService,
    public usersService: UsersService,
    public settings: SettingsService,
    public authService: AuthService,
    public farmService: FarmService,
    public themes: ThemesService,
    public elem: ElementRef,
  ) {
    this.user = <any>JSON.parse(localStorage.getItem("USER") || "{}");

    if (localStorage.getItem("version"))
      this.currentVersion = localStorage.getItem("version");
    this.getLastVersion();
  }
  farm: any = null;
  farmSubsciption: Subscription;
  modeSubsciption: Subscription;
  sidebarSectionSubsciption: Subscription;

  port = "";
  ports = [0, 1];
  sidebar = {
    alerts: true,
    feeders: true,
  };
  permissions = {
    view_refeed: true,
    view_automatic_test: true,
  };
  client: any = null;
  mode: number | "" = 0;
  ngOnDestroy() {
    document.removeEventListener("click", this.checkCloseOffsidebar);
    this.farmSubsciption.unsubscribe();
    this.modeSubsciption.unsubscribe();
  }

  ngOnInit() {
    const validateClient = (prop) => {
      return this.permissionsService.validatePermissionsExistClient(prop);
    };
    const validateSubclient = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };
    this.permissions = {
      view_refeed:
        validateClient("view_refeed") && validateSubclient("view_refeed"),
      view_automatic_test:
        validateClient("view_automatic_test") &&
        validateSubclient("view_automatic_test"),
    };
    if (this.storageService.localValue) {
      const personal_web = this.storageService.localValue;
      if (personal_web) {
        this.uploadPreferenceHeader(personal_web);
        this.is_client =
          !this.globalFunction.validateSoloAdmin() || personal_web.mode === 1;

        this.mode = personal_web.mode;
        this.mode_configuration = "global";
        if (personal_web["theme"]) {
          this.currentTheme = personal_web["theme"];
          this.setTheme();
        } else {
          this.currentTheme = this.themes.getDefaultTheme();
        }
        if (this.is_client) {
          const { farm_id } = this.storageService.localValue;
          farm_id && this.getFarm(farm_id);
        }
        let sidebar_section = localStorage.getItem("sidebar_section");
        if (sidebar_section) {
          let object_sidebar = JSON.parse(sidebar_section);

          this.sidebar.alerts = !object_sidebar.some(
            (item) => item === "sidebar_alert"
          );

          this.sidebar.feeders = !object_sidebar.some(
            (item) => item === "sidebar_feeders"
          );
        }

        if (this.storageService.validateSoloAdmin()) {
          this.getClient();
        }
      }
    }

    this.anyClickClose();

    this.farmSubsciption = this.storageService
      .watchStorage("farmId")
      .subscribe((data: string) => {
        this.getFarm(data);
      });

    this.modeSubsciption = this.storageService
      .watchStorage("mode")
      .subscribe(async (mode) => {
        this.mode = mode;

        if (this.storageService.localValue) {
          this.is_client =
            !this.globalFunction.validateSoloAdmin() || mode === 1;

          if (this.is_client) {
            const { farm_id } = this.storageService.localValue;
            farm_id && this.getFarm(farm_id);
            this.getClient();
          }

          this.mode_configuration = "global";
        }
      });
  }

  getLastVersion() {
    this.versionService.getLastVersion().subscribe((res) => {
      this.newVersion = res.results[0].version;

      if (this.currentVersion) {
        if (
          parseFloat(this.newVersion) > parseFloat(this.currentVersion) ||
          parseFloat(this.newVersion) < parseFloat(this.currentVersion)
        ) {
          this.refresh();
        }
      } else {
        this.refresh();
      }
    });
  }

  refresh() {
    localStorage.setItem("version", this.newVersion);
    location.reload();
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  getFarm(farmId) {
    this.farmService.getFarm(farmId).subscribe((resp) => {
      this.farm = resp;
      let conf = resp.local_conf_automatic_test_alert;

      if (resp.task_test_alert) {
        this.status_test_alert = resp.task_test_alert.enabled;
      }
      if (conf) {
        let minconvert = conf % 60;
        let horconvert = conf / 60;
        this.autimaticTestTime = {
          hour: Math.trunc(horconvert),
          minute: Math.trunc(minconvert),
        };
      } else {
        this.autimaticTestTime = { hour: 0, minute: 0 };
      }

      this.configurationDeviceService
        .getConfiguration(this.farm.id, "farm")
        .subscribe((resp) => {
          this.fuzzy_dpi = resp.fuzzy_dpi;
          this.use_fuzzy_logic = resp.use_fuzzy_logic;
        });
    });
  }

  setTheme() {
    this.themes.setTheme(this.currentTheme);
    this.storageService.localValue.theme = this.currentTheme;
  }

  changeLanguage() {
    const getLanguage = (type) => {
      const find = this.translator
        .getAvailableLanguages()
        .find((item) => item.code === type);
      if (find) {
        return find;
      }
      return {
        code: "",
        text: "",
        image: "",
      };
    };

    if (this.language.code == "spanish") {
      const en = getLanguage("en");
      this.language = en;
      this.setLang(en);
    } else if (this.language.code == "en") {
      const spanish = getLanguage("spanish");
      this.language = spanish;
      this.setLang(spanish);
    }
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(language) {
    const { code } = language;

    let local = this.storageService.localValue;

    let view: "en" | "spanish" = code === "en" ? "spanish" : "en";
    this.language = language;
    let setLocal = { ...local, language: view };

    this.storageService.editPreferences(setLocal)?.subscribe((resp) => {
      this.storageService.localValue = setLocal;
    });
    this.translator.useLanguage(view);
  }

  uploadPreferenceHeader(personal) {
    const getLangs: Array<any> = this.getLangs();
    var ln = window.navigator.language;

    if (personal.language) {
      const lang: any = getLangs.find(
        (item) => item.code === personal.language
      );

      this.language = lang;
      this.translator.useLanguage(lang.code);
    } else {
      if (ln === "en-US" || ln === "en") {
        this.language = getLangs[1];
        this.storageService.localValue.language = getLangs[1].code;
        setTimeout(() => {
          this.translator.useLanguage("en");
        }, 400);
      } else {
        this.language = getLangs[0];
        this.storageService.localValue.language = getLangs[0].code;
        setTimeout(() => {
          this.translator.useLanguage("spanish");
        }, 400);
      }
    }
  }

  anyClickClose() {
    document.addEventListener(
      "click",
      this.checkCloseOffsidebar.bind(this),
      false
    );
  }

  checkCloseOffsidebar(e) {
    const contains =
      this.elem.nativeElement !== e.target &&
      this.elem.nativeElement.contains(e.target);
    if (!contains) {
      this.settings.setLayoutSetting("offsidebarOpen", false);
      this.mode_configuration = "global";
    }
  }

  setAutomaticTime() {
    let { hour, minute }: any = this.autimaticTestTime;
    const { farm_id } = this.storageService.localValue;
    const farmId = farm_id;

    this.farmService
      .tasksScheduleTestAlert(farmId, { hour, minute })
      .subscribe((resp) => {
        this.getFarm(farmId);
      });
  }

  changeDataSpeed(value) {}
  changeAutomaticTesting(
    propiety: "status_test_alert" | "use_refeeding" | "use_fuzzy_logic"
  ) {
    let obj = {
      [propiety]: !this.farm[propiety],
    };

    if (propiety === "use_refeeding") {
      delete obj.farms;
      this.farmService.patchClientFarm(obj, this.farm.id).subscribe((resp) => {
        this.farm[propiety] = !this.farm[propiety];
      });
    }
    if (propiety === "use_fuzzy_logic") {
      this.clientService
        .patchClient(this.client.id, {
          use_fuzzy_logic: !this.client.use_fuzzy_logic,
        })
        .subscribe((resp) => {});
    }
    if (propiety === "status_test_alert") {
      this.farmService
        .tasksStatusTestAlert(this.farm.id, {
          value: this.status_test_alert,
        })
        .subscribe((resp) => {
        });
    }
  }
  getClient = () => {
    const client_id = this.storageService.getClientID();
    if (client_id) {
      this.clientService.getClient(client_id).subscribe((resp: any) => {
        this.client = resp;
      });
    }
  };
  changePort() {
    let farm = localStorage.getItem("farmId");
    if (farm) {
      let body = { port: this.port };
      let query = `?farm_id_in=${farm}`;
      this.framesService
        .FramePost("change_port", query, body)
        .subscribe((item) => {});
    }
  }

  changeSidebarSections = ({ name, value }) => {
    let sidebar_section = localStorage.getItem("sidebar_section");
    if (sidebar_section) {
      let objSidebar: any = JSON.parse(sidebar_section ? sidebar_section : "");
      let index = objSidebar.findIndex((item) => item === name);

      if (index >= 0 && value === "false") {
        objSidebar.splice(index, 1);
      }
      if (index === -1 && value === "true") {
        objSidebar.push(name);
      }
      localStorage.setItem("sidebar_section", JSON.stringify(objSidebar));
      this.storageService.setItem(
        "sidebar_section",
        JSON.stringify(objSidebar)
      );
    }
    if (!sidebar_section) {
      localStorage.setItem("sidebar_section", JSON.stringify([name]));
      this.storageService.setItem("sidebar_section", JSON.stringify([name]));
    }
  };

  changeModeTable() {
    const { mode_table } = this.storageService.localValue;
    const local = this.storageService.localValue;

    let obj = { ...local, mode_table: !mode_table };
    this.storageService.editPreferences(obj)?.subscribe((resp) => {
      this.storageService.localValue = obj;
    });
  }

  logoutAll() {
    this.authService.logoutAll().subscribe((resp) => {
      this.globalFunction.animation(
        "success",
        "",
        "Se han cerrado todas las sesiones"
      );
    });
  }

  changeFont(size: "reduce" | "add" | "default") {
    this.storageService.changeFont(size);
  }
  changeConfig(prop, value) {
    const body = {
      [prop]: value,
    };
    this.configurationDeviceService
      .patchConfiguration(this.farm.id, "farm", body)
      .subscribe(
        (resp) => {
        },
        (err) => {
          this[prop] = !this[prop];
        }
      );
  }
}
