import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigMinMaxComponent } from "./min_max/config";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { FormsModule } from "@angular/forms";
import { AutomaticRequestDeviceComponent } from "./automatic_request/automatic_request";
import { ConfigSensorDeviceComponent } from "./config_sensor_device";

@NgModule({
  imports: [CommonModule, FormsModule, NgxSliderModule],
  providers: [ConfigMinMaxComponent, AutomaticRequestDeviceComponent,ConfigSensorDeviceComponent],
  declarations: [ConfigMinMaxComponent, AutomaticRequestDeviceComponent,ConfigSensorDeviceComponent],
  exports: [ConfigMinMaxComponent, AutomaticRequestDeviceComponent,ConfigSensorDeviceComponent],
})
export class ConfigSensorsDeviceModule {}
