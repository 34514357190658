import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { IconBatteryComponent } from "./icon_battery";

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule],
  providers: [IconBatteryComponent],
  declarations: [IconBatteryComponent],
  exports: [IconBatteryComponent],
  schemas: [],
})
export class IconBatteryModule {}
