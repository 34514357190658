import { BsModalService } from "ngx-bootstrap/modal";
import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  ViewChild,
  TemplateRef,
  HostBinding,
} from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;

import { MenuService } from "../../core/menu/menu.service";
import { AuthService } from "../../routes/services/auth.service";
import { SettingsService } from "../../core/settings/settings.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { StorageService } from "../../routes/services/storage.service";
import { UsersService } from "../../routes/services/users.service";
import { FirebaseService } from "../../routes/services/firebase.service";
import { AngularFireMessaging } from "@angular/fire/messaging";

import {
  GlobalFunction,
  urlAdmin,
  urlClient,
} from "../../routes/services/globalFuntion";
import { IndexedDBService } from "../../routes/services/db/indexedDb.service";
import { ClientsService } from "../../routes/services/clients.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild("profile") public profile;

  menuItems: Array<any>;
  sbclickEvent = "click.sidebar-toggle";
  $doc: any = null;
  link = "";

  user: any;

  configModal = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg modal-config pos-modal",
  };

  socketModeSubcription: Subscription;
  sidebarSectionSubsciption: Subscription;
  permissionsSuscription: Subscription;
  constructor(
    private modalService: BsModalService,
    public translator: TranslatorService,
    public settings: SettingsService,
    public AuthService: AuthService,
    public location: Location,
    public injector: Injector,
    public menu: MenuService,
    public router: Router,
    public storageService: StorageService,
    public userService: UsersService,
    public firebaseService: FirebaseService,
    private afMessaging: AngularFireMessaging,
    public globalFunction: GlobalFunction,
    private indexedDBService: IndexedDBService,
    public clientService: ClientsService
  ) {
    router.events.subscribe((val) => {
      this.link = location.path();
    });
  }

  ngOnDestroy() {
    if (this.$doc) this.$doc.off(this.sbclickEvent);
    this.socketModeSubcription.unsubscribe();
    this.sidebarSectionSubsciption.unsubscribe();
    this.permissionsSuscription.unsubscribe();
  }

  ngOnInit() {
    this.permissionsSuscription = this.storageService
      .watchStorage("permissions")
      .subscribe((permissions) => {
        this.menuItems = this.menu.getMenu();
      });
    this.sidebarSectionSubsciption = this.storageService
      .watchStorage("sidebar_section")
      .subscribe((sidebar_section) => {
        this.menuItems = this.menu.getMenu();
      });

    if (this.storageService.localValue) {
      const personal_web = this.storageService.localValue;
      this.menuItems = this.menu.getMenu();
      let bol = personal_web.hasOwnProperty("isCollapsedText");
      setTimeout(() => {
        const { isCollapsedText } = personal_web;
        this.settings.setLayoutSetting(
          "isCollapsedText",
          bol ? isCollapsedText : false
        );
      }, 300);
    }

    const get_user = async () => {
      const local_user = <any>JSON.parse(localStorage.getItem("USER") || "{}");
      const { mode, client } = this.storageService.localValue;
      if (mode) {
        return client.user;
      }
      if (local_user.is_subclient && local_user.subclient?.client) {
        try {
          const resp = await this.clientService
            .getClient(local_user.subclient.client)
            .toPromise();
          if (resp) {
            return { ...local_user, client: resp };
          }
        } catch (error) {
          return local_user;
        }
      }

      return local_user;
    };
    get_user().then((user) => {
      this.user = user;
    });

    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
    });

    this.socketModeSubcription = this.storageService
      .watchStorage("mode")
      .subscribe((mode: any) => {
        
        get_user().then((user) => {
          this.user = user;
        });

        this.menuItems = this.menu.getMenu();
      });
    // enable sidebar autoclose from extenal clicks
  }

  Modal = (template: TemplateRef<any>, type) => {
    switch (type) {
      case "show":
        this.modalService.show(template, this.configModal);
        break;
      case "hide":
        this.modalService._hideModal(1);
        break;
      default:
    }
  };

  toggleSubmenuHover(event) {
    let self = this;

    event.preventDefault();
    this.removeFloatingNav();

    let ul = $(event.currentTarget.nextElementSibling);
    let anchor = $(event.currentTarget);

    if (!ul.length) {
      return; // if not submenu return
    }

    let $aside = $(".aside-container");
    let $asideInner = $aside.children(".aside-inner"); // for top offset calculation
    let $sidebar = $asideInner.children(".sidebar");
    let mar =
      parseInt($asideInner.css("padding-top"), 0) +
      parseInt($aside.css("padding-top"), 0);
    let itemTop = anchor.parent().position().top + mar - $sidebar.scrollTop();

    let floatingNav = ul.clone().appendTo($aside);
    let vwHeight = document.body.clientHeight;

    // let itemTop = anchor.position().top || anchor.offset().top;

    floatingNav
      .removeClass("opening") // necesary for demo if switched between normal//collapsed mode
      .addClass("nav-floating")
      .css({
        position: this.settings.getLayoutSetting("isFixed")
          ? "fixed"
          : "absolute",
        top: itemTop,
        bottom: floatingNav.outerHeight(true) + itemTop > vwHeight ? 0 : "auto",
      });

    floatingNav
      .on("mouseleave", () => {
        floatingNav.remove();
      })
      .find("a")
      .on("click", function (e) {
        e.preventDefault(); // prevents page reload on click
        // get the exact route path to navigate
        let routeTo = $(this).attr("route");
        if (routeTo) self.router.navigate([routeTo]);
      });

    this.listenForExternalClicks();
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  changeCollapsedText() {
    const local = this.storageService.localValue;
    let setLocal = { ...local, isCollapsedText: !local.isCollapsedText };
    this.storageService.editPreferences(setLocal)?.subscribe((resp) => {
      this.storageService.localValue = setLocal;
    });
    this.settings.toggleLayoutSetting("isCollapsedText");
  }

  listenForExternalClicks() {
    let $doc = $(document).on("click.sidebar", (e) => {
      if (!$(e.target).parents(".aside-container").length) {
        this.removeFloatingNav();
        $doc.off("click.sidebar");
      }
    });
  }

  removeFloatingNav() {
    $(".nav-floating").remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }
  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  isEnabledHover() {
    return this.settings.getLayoutSetting("asideHover");
  }
  logout = async () => {
    const sucess = () => {
      this.indexedDBService.clearAlldb();
      this.router.navigateByUrl("/login");
      this.storageService.animation("success", "Éxito", `Sesión cerrada`);
      localStorage.removeItem("name_firebase");
    };
    const logout = async () => {
      await this.AuthService.logout().subscribe((resp) => {
        sucess();
      });
    };
    try {
      let token: string | null = await this.afMessaging.getToken.toPromise();
      if (token) {
        try {
          await this.afMessaging.deleteToken(token).toPromise();
          await this.firebaseService.deleteByToken(token).toPromise();
          await logout();
        } catch (error) {}
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  };

  getCurrentQuery() {
    const { mode } = this.storageService.localValue;
    return (mode === 0 || !mode) && this.globalFunction.validateSoloAdmin()
      ? "query_admin"
      : "query_client";
  }
  validateCurrentUrl() {
    const { client, mode } = this.storageService.localValue;
    const validateText = (array, text) => {
      let filter = array.filter((item) => text.indexOf(item) !== -1);
      return filter.length > 0;
    };
    const query = this.storageService.localValue[this.getCurrentQuery()];

    if (query && query.current_url) {
      const current_url = query.current_url || "";
      if (mode === 1 && client) {
        if (validateText(urlClient, current_url)) {
          this.router.navigateByUrl(current_url);
        } else {
          this.router.navigateByUrl(`/dashboard`);
        }
      }
      if ((mode === 0 || !mode) && this.globalFunction.validateSoloAdmin()) {
        if (validateText(urlAdmin, current_url)) {
          this.router.navigateByUrl(current_url);
        } else {
          this.router.navigateByUrl("/home");
        }
      }
    } else {
      if ((mode === 0 || !mode) && this.globalFunction.validateSoloAdmin()) {
        this.router.navigateByUrl("/home");
      } else {
        this.router.navigateByUrl(`/dashboard`);
      }
    }
  }
}
