import { TranslatorService } from "../../../core/translator/translator.service";
import { PermissionsService } from "../../services/permissions.service";
import { ParametricsService } from "../../services/parametrics.service";
import { PlantingsService } from "../../services/plantings.service";
import { StorageService } from "../../services/storage.service";
import { ClientsService } from "../../services/clients.service";
import { FeedersService } from "../../services/feeder.service";
import { GlobalFunction } from "../../services/globalFuntion";
import { HelpService } from "../../../core/help/help.service";
import { FramesService } from "../../services/frames.service";
import { SocketService } from "../../services/socket.service";
import { AlertService } from "../../services/alert.service";
import { PondService } from "../../services/pond.service";
import { FarmService } from "../../services/farm.service";
import { TimeZone } from "../../Admin/clients/TimeZone";
import { FormControl, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as moment from "moment";
import {
  ViewEncapsulation,
  TemplateRef,
  Injectable,
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
} from "@angular/core";
import { WaterwiseService } from "../../services/waterwise.service";
import { ConfigurationDeviceService } from "../../services/configuration-device.service";

@Component({
  selector: "app-alerts-list",
  templateUrl: "./alerts.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./alerts.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class AlertsAdminComponent implements OnInit, OnDestroy {
  @ViewChild("modal") public ModalTesting;
  @ViewChild("modalBatteryPanel") public modalBatteryPanel;

  user: any;

  selectedName: string;
  selectedTableType: number = 1;

  minimizedFeeders: boolean = true;

  configModal = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    centered: true,
    size: "xl",
    class: "modal-config",
  };

  clients: any = [];
  clientFarms: any = [];
  clientFarmPonds: any = [];

  parametricsAlerts: any = [];
  historicalAlerts: any = [];
  feeders: any = [];
  waterwises: any = [];
  sensorsAlerts: any = [];

  selectedItems: any = [];

  kindItems = [
    { value: 1, label: "Temperature" },
    { value: 2, label: "Oxygen" },
    { value: 3, label: "Salinity" },
  ];

  FormSearch: FormGroup;
  searchGraph: FormGroup;

  pagination = 1;
  pageSize = 30;
  count = 0;

  loadingData: boolean = true;
  dataError = false;
  dataErrorMessage: any = {};

  TimeZone = TimeZone;

  ArrayTestMonitor = [];
  ArrayTestBatery = [];
  ArrayTestPanel = [];
  ArrayTestVersion = [];
  ArrayTestSenal = [];

  filteredClients: any = [];

  loadingFormClients = true;
  loadingFormFarms = false;
  loadingFormPonds = false;

  public textQueryAlerts = "";

  farmSubsciption: Subscription;
  modeSubsciption: Subscription;

  socketInfoSubsciption: Subscription;

  plantings = [];
  actualPlanting;
  feedersHome = [];
  alerts_table_type = 1;

  permissions = {
    view_tests: true,
  };
  preferenceConfiguration = null;
  viewModalTestDevice = false;

  constructor(
    public permissionsService: PermissionsService,
    public translatorService: TranslatorService,
    public parametryService: ParametricsService,
    public plantingService: PlantingsService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    public feedersService: FeedersService,
    public clientService: ClientsService,
    public framesService: FramesService,
    public socketService: SocketService,
    public alertsService: AlertService,
    public helpService: HelpService,
    public farmService: FarmService,
    public pondService: PondService,
    private modalService: NgbModal,
    public waterwiseService: WaterwiseService,
    public configurationDeviceService: ConfigurationDeviceService
  ) {
    this.FormSearch = new FormGroup({
      client_id: new FormControl(""),
      farm_id: new FormControl(""),
      pond_id: new FormControl(""),
      name: new FormControl(""),
      orderingAdvance: new FormControl(""),
      mac: new FormControl(""),
      dateCreated: new FormControl(""),
      finishCreated: new FormControl(""),
      typeAlert: new FormControl(""),
      category: new FormControl(""),
      planting_id: new FormControl(""),
      kind: new FormControl(""),
      filteredBusinessName: new FormControl(""),
    });
    this.searchGraph = new FormGroup({
      resample_rule: new FormControl("W"),
      value_rule: new FormControl(1),
      start_date: new FormControl(""),
      finish_date: new FormControl(""),
    });

    window.addEventListener("beforeunload", (e) => {
      this.farmSubsciption.unsubscribe();
      this.socketInfoSubsciption.unsubscribe();
      this.modeSubsciption.unsubscribe();
      this.saveValuePreference();
    });
  }

  ngOnDestroy(): void {
    this.saveValuePreference();
    this.farmSubsciption.unsubscribe();
    this.socketInfoSubsciption.unsubscribe();
    this.modeSubsciption.unsubscribe();
  }

  saveValuePreference() {
    const {
      client_id,
      farm_id,
      planting_id,
      typeAlert,
      pond_id,
      orderingAdvance,
      category,
    } = this.FormSearch.value;

    let obj = {
      client_id,
      farm_id,
      planting_id,
      typeAlert,
      pond_id,
      orderingAdvance,
      category,
      feeder_home: this.feedersHome,
      alerts_table_type: this.alerts_table_type,
    };

    this.storageService.saveQueryFilters(obj);
  }

  ngOnInit(): void {
    const setFormValue = (mode) => {
      let getQuery = this.storageService.getQueryFilters({
        ...this.FormSearch.value,
        feeder_home: [],
        alerts_table_type: "",
      });

      this.feedersHome = getQuery.feeder_home ? getQuery.feeder_home : [];
      this.alerts_table_type = getQuery.alerts_table_type
        ? getQuery.alerts_table_type
        : 1;
      for (const key in getQuery) {
        if (this.FormSearch.value.hasOwnProperty(key)) {
          this.FormSearch.controls[key].setValue(getQuery[key]);
        }
      }

      let pond_id = "";
      if (getQuery.pond_id) {
        pond_id = getQuery.pond_id ? getQuery.pond_id : "";
      }

      if (!this.storageService.isAdmin() || mode === 1) {
        if (getQuery.client_id) {
          delete getQuery.client_id;
        }
        const { farm_id } = this.storageService.localValue;
        if (farm_id) {
          this.FormSearch.controls["farm_id"].setValue(farm_id);
          this.selectedFarm(farm_id, 2, pond_id);
          this.getConfiguration(farm_id);
        }
        this.selectedClient(this.user.id);
      }

      if (this.storageService.isAdmin() || mode === 0) {
        this.getClients();
        if (!getQuery.client_id && !getQuery.farm_id) {
          this.FormSearch.controls["pond_id"].setValue("");
        }
        if (!getQuery.client_id) {
          this.FormSearch.controls["pond_id"].setValue("");
          this.FormSearch.controls["farm_id"].setValue("");
        }
        if (!getQuery.farm_id) {
          this.FormSearch.controls["pond_id"].setValue("");
          this.clientFarmPonds = [];
        }
        if (getQuery.client_id) {
          this.selectedClient(getQuery.client_id);
        }
        if (getQuery.farm_id) {
          this.selectedFarm(getQuery.farm_id, 2, pond_id);
          this.getConfiguration(getQuery.farm_id);
        }
      }

      if (this.alerts_table_type) {
        const type = Number(this.alerts_table_type);
        this.changeTableType(type);
        if (type === 1) {
          this.FormSearch.controls["dateCreated"].setValue("");
          this.FormSearch.controls["finishCreated"].setValue("");
        }
      } else {
        this.changeTableType(1);
      }

      this.getData();
    };
    if (this.storageService.localValue) {
      const personal_web = this.storageService.localValue;

      const localUser = localStorage.getItem("USER");
      const user = localUser ? <any>JSON.parse(localUser) : {};
      this.user = user;

      setFormValue(personal_web.mode);
    }

    let input = ["name", "mac"];

    input.forEach((element) => {
      this.FormSearch.controls[element].valueChanges
        .pipe(debounceTime(700))
        .subscribe((search) => {
          this.getData();
        });
    });

    const validate = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };
    this.permissions = {
      view_tests: validate("view_tests"),
    };

    this.farmSubsciption = this.storageService
      .watchStorage("farmId")
      .subscribe((farm_id) => {
        this.FormSearch.controls["farm_id"].setValue(farm_id);
        this.FormSearch.controls["pond_id"].setValue("");
        const { mode } = this.storageService.localValue;

        if (!this.storageService.isAdmin() || mode === 1) {
          this.selectedFarm(farm_id, 2);
          this.getConfiguration(farm_id);
          this.FormSearch.controls["farm_id"].setValue(farm_id);
          this.FormSearch.controls["pond_id"].setValue("");
        }
        this.getData();
      });

    this.modeSubsciption = this.storageService
      .watchStorage("mode")
      .subscribe((mode) => {
        mode == 1 && setFormValue(mode);
      });
    this.socketInfoSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data) {
          let result = this.globalFunction.setDataFeeders(this.feeders, msg);
          this.feeders = [...result.feeders];
        }
      });
  }

  public getClients = () => {
    this.loadingFormClients = true;
    const fields = ["id", "business_name"];
    this.clientService.getClients(`?fields=${fields}`).subscribe((clients) => {
      this.loadingFormClients = false;
      this.clients = clients;
      this.filterClientsList();
    });
  };

  selectedClient(id) {
    this.clientFarms = [];
    this.clientFarmPonds = [];
    this.cleanClientsFilteredList();
    this.pagination = 1;

    this.loadingFormFarms = true;
    this.FormSearch.controls["client_id"].setValue(id);
    if (id) {
      if (this.storageService.isAdmin()) {
        const fields = ["id", "name"];

        this.clientService
          .getClientFarms(`?fields=${fields}`, id)
          .subscribe((farms) => {
            this.loadingFormFarms = false;
            this.clientFarms = farms;
          });
      } else {
        this.FormSearch.controls["client_id"].setValue("");
        const fields = ["id", "name"];
        const query = `?client=${id}&fields=${fields}`;
        this.farmService.getFarms(query).subscribe((resp) => {
          this.loadingFormFarms = false;
          this.clientFarms = resp;
        });
      }
    } else {
      this.loadingFormFarms = false;
    }
  }

  selectedFarm(id, pase, pond?) {
    this.pagination = 1;
    if (id !== "") {
      this.loadingFormPonds = true;
      pond && this.FormSearch.controls["pond_id"].setValue(pond);
      const fields = ["id", "name"];
      this.pondService
        .getPonds(`?farm_id=${id}&ordering=number&fields=${fields}`)
        .subscribe((ponds) => {
          this.loadingFormPonds = false;
          this.clientFarmPonds = ponds;
          pase == 1 && this.getData();
        });
    } else {
      this.clientFarmPonds = [];
      pase == 1 && this.getData();
    }
  }

  selectedPond = async (id) => {
    this.pagination = 1;
    this.FormSearch.controls["pond_id"].setValue(id);
    this.actualPlanting = this.clientFarmPonds.find((item) => {
      return Number(item.id) === Number(id);
    });
    if (this.actualPlanting && this.actualPlanting.planting) {
      this.FormSearch.controls["planting_id"].setValue(
        this.actualPlanting.planting.id
      );
    } else {
      this.FormSearch.controls["planting_id"].setValue("");
    }

    (await id) && this.getPlanting(id);
    await this.getData();
  };
  getPlanting(pond_id) {
    const { farm_id } = this.FormSearch.value;
    const query_farm = farm_id ? `&farm_id=${farm_id}` : "";
    this.plantingService
      .getPlantings(`?pond_id=${pond_id}${query_farm}`)
      .subscribe((resp) => {
        this.plantings = resp;
      });
  }

  cleanClientsFilteredList() {
    this.FormSearch.controls["filteredBusinessName"].setValue("");
    this.filteredClients = this.clients;
  }

  filterClientsList() {
    const { filteredBusinessName } = this.FormSearch.value;

    if (filteredBusinessName) {
      this.filteredClients = this.clients.filter((item: any) => {
        return (
          item.business_name
            .toLowerCase()
            .indexOf(filteredBusinessName.toLowerCase()) > -1
        );
      });
    } else {
      this.filteredClients = this.clients;
    }
  }

  public getData = async () => {
    const {
      dateCreated,
      name,
      mac,
      orderingAdvance,
      finishCreated,
      typeAlert,
      category,
      pond_id,
      client_id,
      farm_id,
      planting_id,
      kind,
    } = this.FormSearch.value;
    let finish: any = moment(dateCreated).add(1, "d");
    let yesterday = moment(finish._d).format("YYYY-MM-DD");
    const validateOrdering = () => {
      let query = "&ordering=";

      let string = orderingAdvance;
      if (this.textQueryAlerts) {
        let order = this.textQueryAlerts[0];
        let caracter = this.textQueryAlerts.slice(1);
        if (order === "-" && string == caracter) {
          string = "";
        }
      }
      let data = [this.textQueryAlerts, string];

      let array = data.filter((item, index) => {
        return data.indexOf(item) === index && item;
      });
      query += array.toString();
      return query;
    };
    const query_pagination = `?page_size=${this.pageSize}${
      this.pagination ? `&page=${this.pagination}` : ""
    }`;
    const query_client = client_id ? `&client_id=${client_id}` : "";
    const query_farm = farm_id ? `&farm_id=${farm_id}` : "";
    const query_pond = pond_id ? `&pond_id=${pond_id}` : "";
    const query_mac = mac ? `&mac=${mac}` : "";
    const query_name = name ? `&name=${name}` : "";

    switch (this.selectedTableType) {
      case 1:
        this.feeders = [];
        this.loadingData = true;

        const fields = [
          "id",
          "name",
          "client",
          "farm",
          "status_version",
          "mac",
          "pond",
          "status_power",
          "alert_dispenser_opening",
          "alert_dispenser",
          "status_dispenser_opening",
          "alert_dispenser_opening",
          "status_dispenser",
          "alert_sprinkle",
          "sprinkler_alerts",
          "status_sprinkler",
          "alert_comm",
          "alert_reset",
          "status_battery",
          "status_panel",
          "alert_battery",
          "alert_panel",
          "status_datetime",
          "status_db",
          "status_ration",
          "sensors",
          "sensor_hydro",
          "status_emptied",
          "status_speed",
          "status_speed_mode",
          "feeding_details",
        ];

        const query_fields = `&fields=` + fields;
        const query =
          query_pagination +
          query_client +
          query_farm +
          query_pond +
          query_name +
          query_mac +
          validateOrdering() +
          query_fields;

        try {
          let resp = await this.feedersService
            .ListFeedersQuery(query)
            .toPromise();
          if (resp) {
            this.loadingData = false;
            this.feeders = resp.results.map((item) => {
              return { ...item, status: false };
            });
            this.feeders.map((obj: any) => (obj.isCollapsed = true));
            this.count = resp.count;

            if (this.storageService.isAdmin()) {
              let all_feeders = [...this.selectedItems, ...this.feeders];
              let feeders = this.duplicate(all_feeders);
              this.socketService.setFeedersSocket(feeders);
            }
          }
        } catch (error) {
          this.dataError = true;
          this.dataErrorMessage = {
            message: error.message,
            name: error.name,
          };
        }

        break;
      case 2:
        this.loadingData = true;
        this.historicalAlerts = [];
        const query_feeder_name = name ? `&feeder_name=${name}` : "";
        const query_date = dateCreated
          ? `&date=${dateCreated},${finishCreated ? finishCreated : yesterday}`
          : "";
        const query_feedeer_mac = mac ? `&feeder_mac=${mac}` : "";
        const query_type_alert = typeAlert ? `&type=${typeAlert}` : "";
        const query_category = category ? `&category=${category}` : "";
        const query_ordering = `&ordering=-date,${this.textQueryAlerts}`;
        const query2 =
          query_pagination +
          query_farm +
          query_pond +
          query_client +
          query_feeder_name +
          query_date +
          query_feedeer_mac +
          query_type_alert +
          query_ordering +
          query_category;

        this.alertsService.getAlerts(query2).subscribe(
          (res) => {
            this.loadingData = false;
            this.historicalAlerts = res.results;
            this.count = res.count;
          },
          (error: any) => {
            this.dataError = true;
            this.dataErrorMessage = {
              message: error.message,
              name: error.name,
            };
          }
        );
        break;
      case 3:
        this.loadingData = true;
        this.parametricsAlerts = [];
        const validatePondFarm = (): String => {
          if ((!farm_id && pond_id) || (farm_id && pond_id)) {
            return `&pond_id=${pond_id}`;
          }
          if (farm_id && !pond_id) {
            return `&farm_id=${farm_id}`;
          }
          return "";
        };

        (await pond_id) && this.getPlanting(pond_id);

        const query_range_date_alert = dateCreated
          ? `&range_date=${dateCreated},${
              finishCreated ? finishCreated : yesterday
            }`
          : "";

        const query_king = kind ? `&kind=${kind}` : "";
        const query_cycle = planting_id ? `&planting_id=${planting_id}` : "";
        const query3 =
          query_pagination +
          query_client +
          validatePondFarm() +
          query_mac +
          query_range_date_alert +
          query_cycle +
          query_king +
          `&ordering=-created`;

        this.parametryService.getParametricsAlerts(query3).subscribe(
          (res) => {
            this.loadingData = false;
            this.parametricsAlerts = res.results ? res.results : res;
            this.parametricsAlerts.map((obj: any) => (obj.isCollapsed = true));
            this.count = res.count;
          },
          (error: any) => {
            this.dataError = true;
            this.dataErrorMessage = {
              message: error.message,
              name: error.name,
            };
          }
        );
        break;
      case 4:
        this.feeders = [];
        this.loadingData = true;
        const query_waterwise =
          query_pagination +
          query_client.replace("client_id", "client") +
          query_farm.replace("farm_id", "farm") +
          query_pond.replace("pond_id", "pond") +
          query_name +
          query_mac +
          validateOrdering();

        try {
          let resp = await this.waterwiseService
            .getWaterwises(query_waterwise)
            .toPromise();
          if (resp) {
            this.loadingData = false;
            console.log(resp);

            this.waterwises = resp.results.map((item) => {
              return { ...item, status: false };
            });
            this.count = resp.count;
            console.log(this.waterwises);
          }
        } catch (error) {
          this.dataError = true;
          this.dataErrorMessage = {
            message: error.message,
            name: error.name,
          };
        }

        break;
      case 5:
        this.feeders = [];
        this.loadingData = true;
        const query_sensors =
          query_pagination +
          query_client.replace("client_id", "client") +
          query_farm.replace("farm_id", "farm") +
          query_pond.replace("pond_id", "pond") +
          query_name +
          query_mac +
          validateOrdering();

        try {
          let resp = await this.waterwiseService
            .getWaterwiseAlerts(query_sensors)
            .toPromise();

          if (resp) {
            this.loadingData = false;

            this.sensorsAlerts = resp.results.map((item) => {
              return { ...item, status: false };
            });
            this.count = resp.count;
          }
        } catch (error) {
          this.dataError = true;
          this.dataErrorMessage = {
            message: error.message,
            name: error.name,
          };
        }

        break;

      default:
        break;
    }
    this.saveValuePreference();
  };

  getlistpagination(page) {
    this.pagination = page;
    this.getData();
  }
  setValueOrderinAdvance(target) {
    this.getData();
  }

  public changeSize(value) {
    this.pageSize = value;
    this.getData();
  }

  changeTableType(selected: number) {
    this.selectedTableType !== selected && (this.pagination = 1);

    this.selectedTableType = selected;
    this.selectedItems = [];

    this.textQueryAlerts = "";

    this.alerts_table_type = selected;

    switch (selected) {
      case 1:
        this.selectedName = this.translatorService.translate(
          "alerts.general.feeder-alerts"
        );
        this.configModal.size = "xl";
        break;
      case 2:
        this.selectedName = this.translatorService.translate(
          "alerts.general.historical-alerts"
        );
        this.configModal.size = "lg";
        break;
      case 3:
        this.selectedName = this.translatorService.translate(
          "alerts.general.parametry-alerts"
        );
        this.configModal.size = "md";
        break;
      case 4:
        this.selectedName = this.translatorService.translate(
          "alerts.general.waterwise-alerts"
        );
        this.configModal.size = "md";
        break;
      default:
        break;
    }
  }

  removeDevice(device: any) {
    let removeIndex = this.selectedItems
      .map((item) => {
        return item.id;
      })
      .indexOf(device);
    let index = this[this.selectedTableType === 1 ? "feeders" : "waterwises"]
      .map((item) => {
        return item.id;
      })
      .indexOf(device);
    if (index >= 0) {
      this[this.selectedTableType === 1 ? "feeders" : "waterwises"][
        index
      ].status = false;
      this.selectedItems.splice(removeIndex, 1);
    }
  }

  resetFeeders(array) {
    if (array.length > 0) {
      let query = `?id_in=${array.map((item) => item.id)}`;
      this.framesService.Frame("reset", query).subscribe((resp) => {});
    }
  }

  validateSelectFeeders() {
    let boolean = false;

    if (this.selectedItems.length > 0) {
      boolean = this.selectedItems.some((item) => item.status);
    }
    return boolean;
  }

  Modal = (template: TemplateRef<any>, type) => {
    switch (type) {
      case "show":
        this.modalService.open(template, this.configModal);
        break;
      case "hide":
        this.resetSelected();
        this.modalService.dismissAll();
        break;
      default:
    }
  };
  viewModalPanel = false;

  showPanelAlerts() {
    if (this.selectedTableType == 1) {
      this.viewModalPanel = true;
    }
    if (this.selectedTableType == 4) {
      this.viewModalTestDevice = true;
    }
  }
  showModal(template: TemplateRef<any>, size = "xl") {
    this.modalService.open(template, {
      backdrop: true,
      keyboard: false,
      windowClass: size == "xl" ? "modal-test" : "",
      size: size,
    });
  }

  public getItemsStatus(feeder) {
    this.selectedItems.forEach((element, idx) => {
      if (element.id === feeder.id) {
        this.selectedItems.splice(idx, 1, feeder);
      }
    });
    this.selectedItems.push(feeder);

    this.selectedItems = this.duplicate(this.selectedItems).filter((item) => {
      return item.status;
    });

    let all_feeders = [...this.selectedItems, ...this.feeders];
    let feeders = this.duplicate(all_feeders);
    this.socketService.setFeedersSocket(feeders);
  }
  duplicate = (feeders) => {
    let hash = {};
    let array = feeders.filter((current) => {
      let exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });
    return array;
  };

  changeAll(status) {
    this.selectedItems = this.globalFunction.validateRepeat(
      this.selectedItems,
      this.feeders,
      status
    );
    let all_feeders = [...this.selectedItems, ...this.feeders];
    let feeders = this.duplicate(all_feeders);
    this.socketService.setFeedersSocket(feeders);
  }

  resetSelected() {
    this.selectedItems = [];
    this[this.selectedTableType === 1 ? "feeders" : "waterwises"] = this[
      this.selectedTableType === 1 ? "feeders" : "waterwises"
    ].map((item) => {
      return { ...item, status: false };
    });
  }

  validateButtonSubmit() {
    return this.globalFunction.validateButtonSubmitBatteryPanel(
      this.searchGraph.value
    );
  }
  validateTextAlert() {
    return this.globalFunction.validateTextAlertBatteryPanel(
      this.searchGraph.value
    );
  }

  validateShowModalBattery = async () => {
    let feeders = this.selectedItems.map((item) => {
      let color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      const { id, name } = item;
      return { id, name, color };
    });

    let global = this.globalFunction.validateNoRepeatByPropiety(
      [...feeders],
      "id"
    );
    this.feedersHome = global;
    if (global.length > 0) {
      await this.getGraph("battery", true);
      await this.getGraph("panel", false);
    }
  };

  chageSelect(t) {
    if (t === "M") this.searchGraph.controls["value_rule"].setValue("");
  }
  labelsBattery = [];
  dataBattery = [];
  dataGraph = {
    battery: {
      data: [],
      labels: [],
    },
    panel: {
      data: [],
      labels: [],
    },
  };
  getGraph = async (type: "battery" | "panel", modal: Boolean) => {
    const { resample_rule, value_rule, start_date, finish_date } =
      this.searchGraph.value;

    let query = `?id_in=${this.feedersHome.map(
      (item: any) => item.id
    )}&resample_rule=${value_rule}${resample_rule}${
      finish_date && start_date
        ? `&date_created=${start_date},${finish_date}`
        : ""
    }`;

    let data = await this.feedersService
      .GetStatusGraph(type, query)
      .toPromise();
    if (data.length > 0) {
      let allDates: Array<any> = [];
      /* Hacemos un array global para almacenar todas las fechas */
      data.forEach((feeder) => {
        let labels = feeder.data.map((item) =>
          moment(item.date).format("YYYY-MM-DD")
        );
        allDates = [...allDates, ...labels];
      });
      /* Quitamos todas las fechas repetidas */
      let unique = allDates.filter(
        (elem, index, self) => index === self.indexOf(elem)
      );

      let newArray = data.map((feeder) => {
        const { feeder_id } = feeder;
        const feed: any = this.feedersHome.find(
          (item: any) => Number(item.id) === Number(feeder_id)
        );

        return {
          data: feeder.data.map((item, idx) => {
            let date = moment(item.date).format("YYYY-MM-DD");
            let validate = unique.findIndex((element) => element === date);
            return validate !== -1 ? Number(item.value.toFixed(2)) : 0;
          }),
          type: "line",
          name: feed.name,
          color: feed.color,
        };
      });
      this.dataGraph = {
        ...this.dataGraph,
        [type]: {
          data: newArray,
          labels: unique,
        },
      };

      modal && this.showModal(this.modalBatteryPanel);
    }
  };
  deleteItem(i) {
    this.feedersHome.splice(i, 1);
    localStorage.setItem("feeders-home", JSON.stringify(this.feedersHome));
    this.getGraph("battery", false);
    this.getGraph("panel", false);
  }
  closePanel() {
    if (this.selectedTableType == 1) {
      this.viewModalPanel = false;
      this.selectedItems = [];
      this.feeders = this.feeders.map((item) => {
        return { ...item, status: false };
      });
    }
    if (this.selectedTableType == 4) {
      this.viewModalTestDevice = false;
      this.selectedItems = [];
      this.waterwises = this.waterwises.map((item) => {
        return { ...item, status: false };
      });
    }
  }

  getConfiguration(farm_id) {
    this.configurationDeviceService
      .getConfiguration(farm_id, "farm")
      .subscribe((resp) => {
        this.preferenceConfiguration = resp;
      });
  }
}
