import { FeedingReportsService } from "../../routes/services/feeding-reports.service";
import { graph_sensor } from "../../routes/Clients/farm/list-feeders/constants";
import { ClientsService } from "../../routes/services/clients.service";
import { StorageService } from "../../routes/services/storage.service";
import { FramesService } from "../../routes/services/frames.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { PondService } from "../../routes/services/pond.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Uris } from "../../routes/services/Uris";
import { FormBuilder } from "@angular/forms";
import moment from "moment";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-view-fuzzy-logic",
  templateUrl: "./view_fuzzy_logic.html",
  styleUrls: ["./view_fuzzy_logic.scss"],
})
export class ViewFuzzyLogicComponent implements OnInit {
  view: "tray" | "equation" | "sensors" = "tray";
  @Input() pond_id: null | number = null;
  @Output() click_recomendation = new EventEmitter();
  @ViewChild("sensor_config") public sensor_config_modal;
  @ViewChild("graph_sensor") public graphSensor;

  @Input() reload: boolean = false;

  tray_data = {
    tray_record: "",
    percent: "",
    recommended: "",
  };

  diffuse_logic = {
    recommendation: null,
    weight: null,
  };

  reason_sensors = {
    reason_type: 0,
    reason_value: 0,
    value: 0,
    created_date: "",
  };

  configModal = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    centered: true,
    size: "lg",
    class: "modal-config",
  };
  grap_option: any = graph_sensor;

  loading_graph = false;
  timeGroup: any;

  loading_tray_data = {
    url: "",
    view: true,
  };
  time_loadings = 300;

  constructor(
    public feedingReportsService: FeedingReportsService,
    public globalFunction: GlobalFunction,
    public clientService: ClientsService,
    public storageService: StorageService,
    public frameService: FramesService,
    public pondService: PondService,
    private modalService: NgbModal,
    public fb: FormBuilder
  ) {
    this.timeGroup = this.fb.group({
      dateOption: "today",
      date: moment().format("YYYY-MM-DD"),
    });
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pond_id"] && changes["pond_id"].currentValue) {
      this.getData(changes["pond_id"].currentValue);
    }
    if (changes["reload"]) {
      if (this.pond_id) {
        this.getData(this.pond_id);
      }
    }
  }

  getData = (id) => {
    if (id) {
      const url_tray = Uris.PONDS + `${id}/tray_data/`;
      this.loading_tray_data = { url: url_tray, view: true };

      this.pondService.trayData(id).subscribe((resp) => {
        this.tray_data = resp;

        setTimeout(() => {
          this.loading_tray_data = { url: url_tray, view: false };
        }, this.time_loadings);
      });
      const query = `?fields=diffuse_logic`;
      this.pondService.getPonds(query, id).subscribe((resp) => {
        this.diffuse_logic = resp.diffuse_logic;
        const query = `?pond_id=${id}&page=1&page_size=1&ordering=-date&projection=false`;
        this.feedingReportsService
          .getFeedingReports(query)
          .subscribe((resp) => {
            if (resp.results.length > 0) {
              this.reason_sensors = {
                ...resp.results[0].fuzzy_logic_update,
                created_date: resp.results[0].created_date,
              };
            }
          });
      });
    }
  };

  recomendation(value: number) {
    this.click_recomendation.emit({
      section: this.view,
      value,
    });
  }

  Modal = (template: TemplateRef<any>, type, config?) => {
    switch (type) {
      case "show":
        this.modalService.open(template, config ? config : this.configModal);
        break;
      case "hide":
        this.modalService.dismissAll();
        break;
      default:
    }
  };
  construct_graph = (data) => {
    const validate_limit = (hour) => {
      const lower = hour - 1;
      const higher = hour + 1;
      if (lower < 1 || higher > 24) {
        return {
          lower: hour,
          higher: hour,
        };
      }
      return {
        lower,
        higher,
      };
    };
    const hours = data.map((item) => (Number(item.hour) + 1).toString());
    /* 
      kgs_feed: Kilos suiministrados
      kgs_target: Kilos objetivo 
    */
    const diet_value = data.map((item) => item.kgs_feed);
    const target_value = data.map((item) => item.kgs_target);

    const detect_alert = data
      .filter((item) => item.title)
      .map((item) => {
        /* kgs_feed !== kgs_target mostrar una alerta */
        const lower_limit = validate_limit(item.hour).lower;
        const higher_limit = item.hour;

        return [
          {
            name: item.title,
            xAxis: lower_limit ? lower_limit : item.hour,
          },
          {
            xAxis: higher_limit,
          },
        ];
      });

    const tooltips = (params) => {
      const item = data[Number(params[0].axisValue) - 1];
      const validate_alert = () => {
        if (item.title) {
          return `<b>${item.title}</b> <hr class="m-1"> 
          ${item.reason}`;
        }
        return "<b>No tiene alerta</b> ";
      };

      if (item) {
        return `${validate_alert()} <br>
        Kilos objetivos: ${item.kgs_target.toFixed(2)} <br>
        Kilos distribuidos: ${item.kgs_feed.toFixed(2)}`;
      } else {
        return "";
      }
    };
    let active = true;

    const pieces = data.map((item) => {
      let iscurrent = !item.title;
      active = iscurrent;
      if (active) {
        return {
          lte: item.hour,
          color: "#30646c",
        };
      } else {
        return {
          lte: item.hour,
          color: "red",
        };
      }
    });
    let series: any = this.grap_option.series;
    series[0] = {
      ...series[0],
      markArea: {
        ...series[0].markArea,
        data: detect_alert,
      },
    }; 

    series[1] = {
      ...series[1],
      data: target_value,
    };
    series[2] = {
      ...series[2],
      data: diet_value,
    };

    return {
      ...this.grap_option,
      tooltip: {
        ...this.grap_option.tooltip,
        formatter: tooltips,
      },
      visualMap: {
        ...this.grap_option.visualMap,
        pieces: pieces,
        seriesIndex: 1
      },
      xAxis: {
        ...this.grap_option.xAxis,
        data: hours,
      },
      series: series,
    };
  };
  viewChart(show_modal = true) {
    this.loading_graph = true;

    let date = `?date=${this.timeGroup.value.date}`;
    this.pondService.getFeedingBehaviorGraph(this.pond_id, date).subscribe(
      (resp) => {
        this.loading_graph = false;
        this.grap_option = this.construct_graph(resp);
        show_modal &&
          this.Modal(this.graphSensor, "show", {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            centered: true,
            size: "xl",
            class: "modal-config",
          });
      },
      (err) => {
        this.loading_graph = false;
      }
    );
  }

  setValueYesterday = () => {
    let yesterday = moment().subtract(24, "hours");
    let firstDate = yesterday.format("YYYY-MM-DD");
    this.timeGroup.controls["date"].setValue(firstDate);
    this.viewChart(false);
  };
  setValueToday = () => {
    let today = moment().format("YYYY-MM-DD");
    this.timeGroup.controls["date"].setValue(today);
    this.viewChart(false);
  };
}
