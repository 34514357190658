const validateStatus = (item, devices) => {
  const error = devices.find(
    (device) => device.device_status[item.name].status === "error"
  );
  if (error) {
    return {
      type: 3,
      btn: "btn-danger",
      check: "fa-times-circle text-danger",
    };
  }
  const loading = devices.find(
    (device) => device.device_status[item.name].status === "loading"
  );
  if (loading) {
    return {
      type: 1,
      btn: "btn-secondary",
      check: "",
    };
  }
  const done = devices.filter(
    (device) => device.device_status[item.name].status === "done"
  ).length;
  if (done > 0) {
    return {
      type: 2,
      btn: "btn-success",
      check: "fa-check-circle text-success",
    };
  }
  return {
    type: 0,
    btn: "btn-gray",
    check: "fa-circle text-gray",
  };
};

const validateCountStatus = (item, devices, status) => {
  return devices.filter((device) =>
    status ? device.device_status[item.name].status === status : true
  ).length;
};
const validateCountByPropiety = (
  item,
  devices,
  status,
  propiety,
  value = true
) => {
  return devices.filter((device) =>
    status
      ? device.device_status[item.name].status === status
      : device[propiety] === value
  ).length;
};
const validateDevices = (devices, prop = "", value = true) => {
  return devices.filter((device) => (prop ? device[prop] === value : true))
};
export const menu = [
  {
    label: "testing.battery-test",
    icon: "bateria-blanco.svg",
    name: "battery",
    sent: "",
    countSatus: (item, devices, status) => {
      return validateCountStatus(item, devices, status);
    },
    validateStatus: (item, devices) => {
      return validateStatus(item, devices);
    },
    validateDevices: (devices) => {
      return validateDevices(devices);
    },
  },
  {
    label: "testing.panel-test",
    icon: "panel-blanco.svg",
    name: "panel",
    sent: "",
    countSatus: (item, devices, status) => {
      return validateCountStatus(item, devices, status);
    },
    validateStatus: (item, devices) => {
      return validateStatus(item, devices);
    },
    validateDevices: (devices) => {
      return validateDevices(devices);
    },
  },
  {
    label: "testing.turn-on",
    icon: "power-blanco.svg",
    name: "turn-on",
    sent: "",
    countSatus: (item, devices, status) => {
      return validateCountByPropiety(item, devices, status, "power");
    },
    validateStatus: (item, devices) => {
      return validateStatus(item, devices);
    },
    validateDevices: (devices) => {
      return validateDevices(devices, "power", true);
    },
  },
  {
    label: "testing.turn-off",
    icon: "power-off-blanco.svg",
    name: "turn-off",
    sent: "",
    countSatus: (item, devices, status) => {
      return validateCountByPropiety(
        item,
        devices,
        status,
        "power",
        false
      );
    },
    validateStatus: (item, devices) => {
      return validateStatus(item, devices);
    },
    validateDevices: (devices) => {
      return validateDevices(devices, "power", false);
    },
  },
  {
    label: "version",
    icon: "temporal-blanco.svg",
    name: "version",
    sent: "",
    countSatus: (item, devices, status) => {
      return validateCountStatus(item, devices, status);
    },
    validateStatus: (item, devices) => {
      return validateStatus(item, devices);
    },
    validateDevices: (devices) => {
      return validateDevices(devices);
    },
  },
];
