import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PaginationComponent } from "./pagination/pagination.component";
import { plantingsComponent } from "./plantings/plantings";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FeedComponent } from "./feed/feed.component";
import { GatewaysComponent } from "./gateways/gateways.component";
import { HarvestsComponent } from "./harvests/harvests.component";
import { TwoDigitDecimaNumberDirective } from "./directives/twodecimal";
import { FeederFormComponent } from "./feederForm/feederForm";
import { RationModalComponent } from "./ration-modal/ration-modal.component";

import { TranslateModule } from "@ngx-translate/core";
import { ObservationsComponent } from "./observations/observations.component";
import { SettingsPanelComponent } from "./settings-panel/settings-panel";
import { ImageCropperComponent } from "./ImageCropper/imageCropper";
import { ImageCropperModule } from "ngx-image-cropper";
import { ScrollSpyDirective } from "./settings-modal/scroll-spy.directive";
import { SpeedComponent } from "./speed/speed";
import { SensedComponent } from "./sensed/sensed";
import { SettingModalComponent } from "./settings-modal/settings-modal.component";
import { AlertsSettingComponent } from "./settings-modal/alerts/alerts";
import { LoaderComponentSettings } from "./settings-modal/loader/loader.component";
import { SafeHtmlPipe } from "../core/pipe/html.pipe";
import { SeasonsComponent } from "./seasons/seasons.component";
import { TitleComponent } from "./title/title";
import { ExtendedHarvestComponent } from "./extended-harvest/extended-harvest.component";
import { NgbAccordionModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Models3dComponent } from "./models3d/models3d";

import { CalendarComponent } from "./calendar/calendar";
import { FullCalendarModule } from "@fullcalendar/angular"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!

import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { QrComponent } from "./qr/qr";
import { AutomaticValueComponent } from "./automatic_value/automatic_value";
import { CalibrationFoodComponent } from "./food-calibration/food";
import { FormWaterwiseComponent } from "../routes/Admin/waterwises/form/form";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { environment } from "../../environments/environment";
import { ProgressBarComponent } from "./progress_bar/progress_bar";
import { PanelAlertsComponent } from "./panel-alerts/panel-alerts";
import { MenuPanelAlertComponent } from "./panel-alerts/menu/menu";
import { ButtonBasicComponent } from "./panel-alerts/menu/buttons/basic/basic";
import { PanelAlertListComponent } from "./panel-alerts/feeder-alerts/alerts";
import { LoaderPanelAlertComponent } from "./panel-alerts/feeder-alerts/loader/loader";
import { MonitorAlertsComponent } from "./panel-alerts/feeder-alerts/monitor/monitor";
import { PanelAlertListPondsComponent } from "./panel-alerts/pond-alerts/alerts";

import { MenuEmptyComponent } from "./panel-alerts/menu/empty/empty";
import { NgxGraphComponent } from "./ngx-graph/ngx-graph";

import { TimeQueryComponent } from "./time-query/time-query";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MinuteTimerComponent } from "./minute_timer/minute_timer";
import { DateSliderComponent } from "./date-slider/date-slider";
import { TestFeedComponent } from "./feed/test/test";
import { ViewAlertComponent } from "./feed/test/view_alert/view_alert";
import { CustomSelectComponent } from "./custom_select/custom_select";
import { ViewExcelComponent } from "./view-excel/view-excel";
import { ViewFuzzyLogicComponent } from "./view_fuzzy_logic/view_fuzzy_logic";
import { ConfigSensorsComponent } from "./view_fuzzy_logic/config_sensors/config_sensors";
import { DndDirective } from "../core/directives/dnd.directive";
import { UploadFilesComponent } from "./upload_files/upload_files";
import { MapaComponent } from "./map/map";
import { MarkerAlertComponent } from "./map/marker/marker";
import { LoadingTimeComponent } from "./loading/loading_time/loading_time";

import { LoadingModule } from "./loading/loading.module";
import { ConfigSensorsDeviceModule } from "./config_sensors_device/config_sensors_device.module";
import { MarkerWaterwiseComponent } from "./map/marker_waterwise/marker_waterwise";
import { ViewContentDeviceModule } from "./content_device/content.module";
import { VirtualWeightModule } from "./virtual_weight/virtual_weight.module";
import { EchartModule } from "./echart/echart.module";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { ImageColorModule } from "./image_color/image_color.module";
import { SettingFeedersModule } from "./settings-feeders/settings-feeders.module";
import { FrameDeviceDinamicModule } from "./frames_device_dinamic/frame_device.module";
import { IconBatteryModule } from "./icon_battery/icon_battery.module";

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NgbModule,
    ImageCropperModule,
    NgxQRCodeModule,
    NgbAccordionModule,
    ViewContentDeviceModule,
    NgxGraphModule,
    FullCalendarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apikeyMap,
      libraries: ["drawing", "geometry"],
    }),
    ScrollingModule,
    LoadingModule,
    ConfigSensorsDeviceModule,
    VirtualWeightModule,
    EchartModule,
    ImageColorModule,
    FrameDeviceDinamicModule,
    IconBatteryModule
  ],
  providers: [
    PaginationComponent,
    plantingsComponent,
    FeedComponent,
    GatewaysComponent,
    HarvestsComponent,
    FeederFormComponent,
    RationModalComponent,
    TimeQueryComponent,
    LoaderComponentSettings,
    ObservationsComponent,
    SettingsPanelComponent,
    ImageCropperComponent,
    SpeedComponent,
    SensedComponent,
    SettingModalComponent,
    SettingFeedersModule,
    SeasonsComponent,
    TitleComponent,
    ExtendedHarvestComponent,
    CalendarComponent,
    QrComponent,
    Models3dComponent,
    AutomaticValueComponent,
    CalibrationFoodComponent,
    FormWaterwiseComponent,
    GoogleMapsAPIWrapper,
    ProgressBarComponent,
    PanelAlertsComponent,
    MenuPanelAlertComponent,
    ButtonBasicComponent,
    PanelAlertListComponent,
    LoaderPanelAlertComponent,
    MonitorAlertsComponent,
    PanelAlertListPondsComponent,
    MenuEmptyComponent,
    NgxGraphComponent,
    MinuteTimerComponent,
    DateSliderComponent,
    TestFeedComponent,
    ViewAlertComponent,
    CustomSelectComponent,
    ViewExcelComponent,
    ViewFuzzyLogicComponent,
    ConfigSensorsComponent,
    UploadFilesComponent,
    MapaComponent,
    MarkerAlertComponent,
    MarkerWaterwiseComponent,
    LoadingTimeComponent,
  ],
  declarations: [
    PaginationComponent,
    plantingsComponent,
    FeedComponent,
    GatewaysComponent,
    HarvestsComponent,
    TwoDigitDecimaNumberDirective,
    TimeQueryComponent,
    FeederFormComponent,
    RationModalComponent,
    LoaderComponentSettings,
    ObservationsComponent,
    SettingsPanelComponent,
    ImageCropperComponent,
    ScrollSpyDirective,
    SpeedComponent,
    SensedComponent,
    SettingModalComponent,
    AlertsSettingComponent,
    SafeHtmlPipe,
    SeasonsComponent,
    TitleComponent,
    ExtendedHarvestComponent,
    CalendarComponent,
    QrComponent,
    Models3dComponent,
    AutomaticValueComponent,
    CalibrationFoodComponent,
    FormWaterwiseComponent,
    ProgressBarComponent,
    PanelAlertsComponent,
    MenuPanelAlertComponent,
    ButtonBasicComponent,
    PanelAlertListComponent,
    LoaderPanelAlertComponent,
    MonitorAlertsComponent,
    PanelAlertListPondsComponent,
    MenuEmptyComponent,
    NgxGraphComponent,
    MinuteTimerComponent,
    DateSliderComponent,
    TestFeedComponent,
    ViewAlertComponent,
    CustomSelectComponent,
    ViewExcelComponent,
    ViewFuzzyLogicComponent,
    ConfigSensorsComponent,
    DndDirective,
    UploadFilesComponent,
    MapaComponent,
    MarkerAlertComponent,
    MarkerWaterwiseComponent,
    LoadingTimeComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    PaginationComponent,
    plantingsComponent,
    FeedComponent,
    GatewaysComponent,
    HarvestsComponent,
    FeederFormComponent,
    TimeQueryComponent,
    RationModalComponent,
    LoaderComponentSettings,
    ObservationsComponent,
    SettingsPanelComponent,
    ImageCropperComponent,
    SpeedComponent,
    SensedComponent,
    SettingModalComponent,
    SettingFeedersModule,
    SeasonsComponent,
    TitleComponent,
    ExtendedHarvestComponent,
    CalendarComponent,
    QrComponent,
    NgbModule,
    Models3dComponent,
    AutomaticValueComponent,
    CalibrationFoodComponent,
    FormWaterwiseComponent,
    ProgressBarComponent,
    PanelAlertsComponent,
    MenuPanelAlertComponent,
    ButtonBasicComponent,
    PanelAlertListComponent,
    LoaderPanelAlertComponent,
    MonitorAlertsComponent,
    PanelAlertListPondsComponent,
    MenuEmptyComponent,
    NgxGraphComponent,
    MinuteTimerComponent,
    DateSliderComponent,
    TestFeedComponent,
    ViewAlertComponent,
    CustomSelectComponent,
    ViewExcelComponent,
    ViewFuzzyLogicComponent,
    ConfigSensorsComponent,
    UploadFilesComponent,
    MapaComponent,
    MarkerAlertComponent,
    MarkerWaterwiseComponent,
    LoadingTimeComponent,
    ConfigSensorsDeviceModule,
    LoadingModule,
    VirtualWeightModule,
    ViewContentDeviceModule,
    ImageColorModule,
    EchartModule,
    FrameDeviceDinamicModule,
    IconBatteryModule
   ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
