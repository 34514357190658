import { StorageService, actions } from "../routes/services/storage.service";
import { PermissionsService } from "../routes/services/permissions.service";
import { ClientsService } from "../routes/services/clients.service";
import { SocketService } from "../routes/services/socket.service";
import { UsersService } from "../routes/services/users.service";
import { PondService } from "../routes/services/pond.service";
import { MenuService } from "../core/menu/menu.service";
import { Menu } from "../routes/menu";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from "@angular/router";
import {
  GlobalFunction,
  urlClient,
  urlAdmin,
} from "../routes/services/globalFuntion";

@Injectable({
  providedIn: "root",
})
export class PreferenceGuard implements CanActivate {
  constructor(
    public permissionsService: PermissionsService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    public clientService: ClientsService,
    public socketService: SocketService,
    public userService: UsersService,
    public menuService: MenuService,
    public pondService: PondService,
    public router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.getPetition(route, state);
  }
  getPetition = async (route, state) => {
    const validateUrlMenu = () => {
      const menu = this.menuService.getMenu();
      let getQuery = this.storageService.getQueryFilters({
        current_url: "",
      });

      const { mode } = this.storageService.localValue;
      const validateUrl = () => {
        const is_admin = this.storageService.validateSoloAdmin();
        if ((is_admin && mode === 1) || !is_admin) {
          return urlClient;
        }
        return urlAdmin;
      };
      const similitud = (text1, text2) => {
        if (text1 && text2) {
          if (text1.startsWith(text2) || text2.startsWith(text1)) {
            return true;
          }
          if (text1.includes(text2) || text2.includes(text1)) {
            return true;
          }
        }
        return false;
      };
      const pathname = window.location.pathname;
      const exist = validateUrl().some(
        (item) => pathname !== "/" && similitud(item, pathname)
      );

      if (exist) {
        this.router.navigateByUrl(pathname);
      } else if (menu.length > 0) {
        /* Validacion para current_url admin o cliente */
        const validate_current =
          getQuery.current_url !== "/" && getQuery.current_url !== "";
        if (this.storageService.localValue && validate_current) {
          const url = getQuery.current_url;
          const regex = /^\/granja\/(\d+)$/;
          const match = url && url.match(regex);

          if (match) {
            const id = match[1]; // Extrae el número capturado en el primer grupo
            this.pondService.getPonds("?fields=id", id).subscribe(
              (resp) => {
                this.router.navigateByUrl(url);
              },
              (err) => {
                this.router.navigateByUrl(menu[0].link);
              }
            );
          } else {
            const some = menu.some((item) => {
              if (item.submenu && item.submenu.length > 0) {
                return item.submenu.some((submenu) =>
                  similitud(submenu.link, url)
                );
              }
              return similitud(item.link, url);
            });

            if (some) {
              this.router.navigateByUrl(url);
            } else {
              /* Agregar validacion de url de cliente y admin si coincide */
              const validateLink = () => {
                if (menu[0] && menu[0].link) {
                  return menu[0].link;
                }
                if (menu[0].submenu && menu[0].submenu.length > 0) {
                  return menu[0].submenu[0].link;
                }
              };
              this.router.navigateByUrl(validateLink());
            }
          }
        } else {
          this.router.navigateByUrl(menu[0].link);
        }
      }
    };
    const validateExistPermission = () => {
      let bool = this.permissionsService.validatePermissionsExistSubclient(
        route.data.permission
      );

      if (!bool) {
        validateUrlMenu();
      }

      return bool;
    };
    const validateMenu = async () => {
      let Localuser: any = localStorage.getItem("USER");
      let ObjectUser: any = Localuser ? JSON.parse(Localuser) : "";

      if (ObjectUser) {
        const { mode } = this.storageService.localValue;
        const permissions = this.permissionsService.all_permissions || null;
        const permission_client =
          this.permissionsService.client_permissions || null;

        let menu = Menu.menu(ObjectUser, mode, permissions, permission_client);
        this.menuService.push(menu);
      }
    };

    let bool = false;
    const validateBool = () => {
      if (route.data && route.data.is_admin) {
        bool = this.storageService.validateSoloAdmin();

        if (!bool) {
          validateUrlMenu();
        }
      } else if (
        route &&
        route.data &&
        route.data.permission &&
        !this.storageService.validateSoloAdmin()
      ) {
        /* Validamos si la ruta tiene un rol y existe en el objeto permisos */
        bool = validateExistPermission();
      } else {
        if (state.url === "/" || state.url === "") {
          validateUrlMenu();
        }
        bool = true;
      }
    };
    try {
      const loca_user: string | null = localStorage.getItem("USER") || null;
      /* Si no tenemos un cliente en el localstorage vamos a reedireccionar siempre al landpange */
      /* Simulacion de router redirecTo  */
      if (!loca_user) {
        bool = true;
        this.router.navigateByUrl("/landpage");
      } else {
        if (!this.permissionsService.all_permissions && loca_user) {
          let permissions = await this.permissionsService
            .userPermissions()
            .toPromise();
          if (permissions) {
            this.permissionsService.all_permissions = permissions;
          }
          const user = loca_user ? JSON.parse(loca_user) : null;
          if (user && user.is_subclient) {
            const client_permissions = await this.permissionsService
              .getClientPermissionsWithSubclient()
              .toPromise();
            if (client_permissions) {
              this.permissionsService.client_permissions = client_permissions;
            }
          }
        }
        if (!this.storageService.view_modules && loca_user) {
          const data = await this.storageService.getPreferences().toPromise();
          const user = await this.userService.getUserInfo().toPromise();

          if (user) {
            localStorage.setItem("USER", JSON.stringify(user));
          }

          if (user.last_login) {
            /* TENEMOS QUE PONER LA FECHA y HORA DE LA ACTUALIZACION
          PARA FORZAR AL USUARIO QUE REESTABLEZCA LA CONTRASEÑA */

            let date_updated = new Date(
              `2024-02-01T16:51:14.038438-07:00`
            ).getTime();
            let last_login = new Date(user.last_login).getTime();
            const cal = last_login < date_updated;

            if (cal && date_updated) {
              /* Vamos a reedirigir al cambio de contraseña */
              this.router.navigateByUrl("/lock");
              this.globalFunction.animation(
                "error",
                "¡Importante! Cambio de Contraseña",
                "Refuerza la Seguridad de tu Cuenta"
              );
            } else {
              if (data && data.personal_web && user) {
                let localValue = data.personal_web;
                const insert_menu = async () => {
                  this.storageService.localValue = localValue;
                  await validateMenu();
                  this.storageService.view_modules = true;
                  await validateBool();
                };
                if (
                  user.permissions &&
                  user.user_type === 0 &&
                  user.permissions.alerts_only
                ) {
                  localValue.current_url = "/alerts";
                  localValue.client = null;
                  localValue.mode = 0;
                  insert_menu();
                } else {
                  /* Validamos las url */
                  await insert_menu();
                  await validateUrlMenu();
                }

                let actions: Array<actions> = [];
                if (
                  (localValue.farm_id &&
                    !this.storageService.validateSoloAdmin()) ||
                  localValue.mode === 1
                ) {
                  actions = [
                    {
                      type: "gateways.add_listener",
                      data: { farm: Number(localValue.farm_id) },
                    },
                  ];
                } else {
                  actions = [{ type: "ping" }];
                }
                await this.socketService.connectSocket(actions);
              }
            }
          }
        } else {
          validateBool();
        }
      }
    } catch (error) {
      bool = false;
      this.storageService.view_modules = false;
    }
    if (!bool) {
      this.globalFunction.animation(
        "error",
        "ERROR",
        "Usted no tiene permisos para ingresar a esta pantalla"
      );
    }

    return bool;
  };
}
