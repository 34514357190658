import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-config-sensors-device",
  templateUrl: "./config_sensor_device.html",
  styleUrls: ["./config_sensor_device.scss"],
})
export class ConfigSensorDeviceComponent implements OnInit {
  config_min_max = true;
  @Input() type: "feeder" | "waterwise" | "farm" | "pond" = "feeder";

  @Input() id: number | "" = "";
  @Input() massiveUpdate = false;
  @Input() selectedItems = [];

  constructor() {}
  ngOnInit(): void {
    console.log(this.id, this.massiveUpdate, this.selectedItems);
  }
}
